.progressive{
    position:relative;
    display:block;
    overflow:hidden;
    outline:none;
}
.progressive img{
    display:flex;
    align-self: center;
    justify-self: center;
    // width:100%;
    
    // max-width:none;
    // height:auto;
    border:0;

    // object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;
}
.progressive img.preview{
    filter:blur(2vw);
    // max-width: unset;
    // max-height: unset;
    // width: 100%;
    // height: 100%;
    
    // transform:scale(1.05)
}
.progressive img.reveal{
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%) !important;
    animation:progressiveReveal 0.3s linear
}
@keyframes progressiveReveal{
    0%{
        // transform:scale(1.05);
        opacity:0
    }
    to{
        // transform:scale(1);
        opacity:1
    }
}