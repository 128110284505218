/* FONTS */

@font-face {
    font-family: 'rubik_light';
    // src: url('../fonts/MyriadPro-Semibold.eot');
    // src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype');
    // src: url('../fonts/MyriadPro-Semibold.woff') format('woff');
    src: url('../fonts/Rubik-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'rubik_reg';
    // src: url('../fonts/MyriadPro-Semibold.eot');
    // src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype');
    // src: url('../fonts/MyriadPro-Semibold.woff') format('woff');
    src: url('../fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'rubik_med';
    // src: url('../fonts/MyriadPro-Semibold.eot');
    // src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype');
    // src: url('../fonts/MyriadPro-Semibold.woff') format('woff');
    src: url('../fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




// ANIMATIONS



@keyframes fade {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes fadeup {
    from{
        opacity: 0;
        transform: translateY(10px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}








// PAGINATION

.custom_pagination{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    .pag_link{
        color: #2a2a2a;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 3px;
        padding: 10px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        min-width: 40px;
        min-height: 40px;
        user-select: none;
        transition: all 0.2s;
        &:hover{
            color: #f1002e;
            .arrow{
                filter: grayscale(0%);
            }
        }
        .arrow{
            filter: grayscale(100%);
            transition: all 0.2s;
        }
        &.prev,
        &.next {
            padding: 10px 20px;
            // text-transform: uppercase;
            font-size: 11px;
            font-family: 'rubik_med';
        }
        &.prev {
            margin: 0 3px 0 0;
            .arrow{
                transform: rotate(180deg);
                margin-right: 10px;
            }
        }
        &.next {
            margin: 0 0 0 3px;
            .arrow{
                margin-left: 10px;
            }
        }
        &.current{
            border: 1px solid transparent;
            background: #f1f1f1;
            &:hover{
                color: #2a2a2a;
            }
        }
    }
}


/* CUSTOM_FORMS_CONTROLS */

.custom_text_input{
    background: #f6f6f6;
    width: 100%;
    padding: 12px 20px;
    font-family: 'rubik_med';
    color: #2a2a2a;
    transition: all 0.2s;
    border: 1px solid #f6f6f6;
    margin-bottom: 10px;
    font-size: 12px;
    &::placeholder{
        color: #999999;
        // font-style: italic;
        opacity: 1;
        font-family: 'rubik_reg';
    }
    &:focus{
        background: #ffffff;
        border: 1px solid #999999;
        &::placeholder{
            opacity: 0;
        }
    }
}

.custom_checkbox{
    input{
        position: absolute;
        z-index: -1;
        top: 0;
        opacity: 0;
        &+label{
            display: block;
            position: relative;
            color: #2a2a2a;
            cursor: pointer;
            transition: all 0.2s;
            user-select: none;
            padding: 4px 4px 4px 24px;
            &::first-letter{
                text-transform: uppercase;
            }
            &::before{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: #ffffff;
                border-radius: 2px;
                border: 1px solid #cccccc;
                width: 14px;
                height: 14px;
                box-sizing: unset;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 85% 80%;
                transition: all 0.2s;
            }
        }
        &:checked+label::before{
            border: 1px solid #f1002e;
            background-color: #f1002e;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");            
        }
        &:not(:checked):not(:disabled)+label:hover{
            color: #f1002e;
            &::before{
                border: 1px solid #f28796;
            }
        }
        &:not(:disabled):active+label:hover{
            color: #f1002e;
            &::before{
                border: 1px solid #f28796;
                background-color: #f28796;
            }
        }
        &:disabled+label{
            color: #aaaaaa;
            cursor: default;
            &::before{
                background-color: #dddddd;
                border: 1px solid #dddddd;
            }
        }
    }
}

.custom_textarea{
    background: #f6f6f6;
    width: 100%;
    padding: 12px 20px;
    font-family: 'rubik_med';
    color: #2a2a2a;
    transition: all 0.2s;
    border: 1px solid #f6f6f6;
    margin-bottom: 10px;
    font-size: 12px;
    max-width: 100%;
    min-width: 100%;
    height: 100px;
    min-height: 100px;
    max-height: 100px;

    &::placeholder{
        color: #999999;
        // font-style: italic;
        opacity: 1;
        font-family: 'rubik_reg';
    }
    &:focus{
        background: #ffffff;
        border: 1px solid #999999;
        &::placeholder{
            opacity: 0;
        }
    }
}

.custom_select{
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    color: #2a2a2a;
    margin-bottom: 10px;
    font-size: 12px;
    height: 46px;
    font-family: 'rubik_med';
    // font-family: 'rubik_med';

    // min-width: 15ch;
    // max-width: 30ch;

    border: 1px solid #f6f6f6;
    // border-radius: 0.25em;
    

    // font-size: 1.25rem;
    cursor: pointer;
    // line-height: 1.1;

    background-color: #f6f6f6;

    select {
        appearance: none;
        background-color: transparent;
        
        border: none;
        padding: 12px 40px 12px 20px;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        height: 46px;
        z-index: 1;
      
        &::-ms-expand {
          display: none;
        }

        option{
            font-family: 'rubik_reg';
        }

        outline: none;
    }

    select,
    &::after {
        grid-area: select;
    }

    &::after {
        content: "";
        justify-self: end;
        width: 8px;
        height: 4px;
        background-color: #2a2a2a;
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        margin-right: 20px;
    }

    // select:focus + .focus {
    //     position: absolute;
    //     top: -1px;
    //     left: -1px;
    //     right: -1px;
    //     bottom: -1px;
    //     border: 2px solid #2a2a2a;
    //     border-radius: inherit;
    // }
}

// TABLES

.table_container{
    max-width: 100%;
    overflow-x: auto;
}

table{
    width: 100%;
    border: 1px solid #e5e5e5;
    border-spacing: 0px;
    thead{
        background: #f1f1f1;
        // font-family: 'rubik_med';
        font-weight: normal;
        th{
            border-bottom: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
            padding: 25px 30px;
            user-select: none;
            white-space: nowrap;
            &:last-child{
                border-right: none;
            }
            &:first-child{
                text-align: left;
            }
        }
    }
    tbody{
        tr{
            background: #ffffff;
            transition: all 0.2s;
            &:hover{
                background: #f6f6f6;
            }
            td{
                border-bottom: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
                padding: 20px 30px;
                &:last-child{
                    border-right: none;
                }
            }
            &:last-child{
                td{
                    border-bottom: none;
                }
            }
        }
    }
}

// MESSAGES

.msg_container{
    position: fixed;
    width: auto;
    z-index: 3000;
    bottom: 20px;
    right: 20px;
    height: auto;
    display: flex;
    flex-direction: column;

    .msg_item{
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        padding: 30px 50px;
        border-radius: 5px;
        color: #2a2a2a;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        font-family: 'rubik_med';
        animation: fadeup 0.3s;
    }

}

@media(max-width:539px){
    .msg_container{
        width: calc(100% - 40px);
        .msg_item{
            white-space: normal;
            text-align: center;
            font-family: 'rubik_reg';
        }
    }
}



/* BASE_STYLES */

*, *:hover, *:focus{
    outline: none;
}

*, html, body{
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    font-family: 'rubik_reg';
    font-size: 13px;
    color: #2a2a2a;
    // display: flex;
}

h1{
    font-family: 'rubik_light';
    font-size: 32px;
    margin-bottom: 50px;
    margin-top: 50px;
    font-weight: normal;
    cursor: default;
}
// img{
//     width: 100%;
// }
// .leftbar_helper{
//     display: inline-block;
//     width: 320px;
//     background: transparent;
//     height: 100vh;
// }
// .main{
//     display: inline-block;
//     width: calc(100% - 320px);
//     // transform: translateX(320px);
//     transition: transform 0.3s ease-in-out;
//     margin-top: 80px;
//     &.open{
//         transform: translateX(-320px);
//     }
// }
.mask{
    display: block;
    z-index: 1500;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out 0s, visibility 0s ease-in-out 0.3s;

    &.open{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in 0s, visibility 0s ease-in 0s;
    }
}

#regionModal{
    .modal-body{
        position: relative;
        background-color: #f9f9f9;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        padding: 40px 60px;
        h5{
            font-size: 18px;
            text-transform: uppercase;
            font-family: 'prox_sb';
            user-select: none;
        }
        .town_input{
            .custom_input{
                position: relative;
                width: 100%;
                background: #ffffff;
                border-radius: 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
                font-size: 12px;

                input{
                    border: 1px solid #cccccc;
                    background: transparent;
                    padding: 10px 30px;
                    font-family: 'prox_sb';
                    color: #333333;
                    display: flex;
                    flex-grow: 1;
                    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
                    transition: box-shadow 0.3s;
                    
                    &:focus{
                        outline: none;
                        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
                    }					
                }
                label{
                    position: absolute;
                    left: 30px;
                    font-family: 'prox_sb';
                    text-transform: uppercase;
                    font-size: 12px;
                    top: 15px;
                    pointer-events: none;
                    -webkit-transform-origin: 0 0;
                    transform-origin: 0 0;
                    -webkit-transition: -webkit-transform 0.2s, color 1s;
                    transition: transform 0.2s, color 1s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                    user-select: none;
                }
                input:focus + label,
                input:not([value=""]) + label{
                    color: #999999;
                    transform: translate3d(-30px, -32px, 0) scale3d(0.8, 0.8, 1);
                }
                .dropdown_list{
                    display: none;
                    position: absolute;
                    top: calc(100% + 10px);
                    left: 0;
                    width: 100%;
                    background-color: #ffffff;
                    border-radius: 6px;
                    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
                    height: auto;
                    padding: 30px 50px;
                    overflow: auto;
                    max-height: 300px;
                    &.show{
                        display: block;
                    }
                    .item{
                        padding: 5px 0;
                        cursor: pointer;
                        color: #2A2A2A;
                        font-family: 'prox_sb';
                        transition: color 0.2s;
                        &:hover{
                            color: #f1002e;
                        }
                        span{
                            font-family: 'prox_rg';
                            color: #666666;
                        }
                    }
                }

            }
        }
        .big_cities{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            margin-top: 30px;
            .item{
                padding: 5px 20px;
                white-space: nowrap;
                margin: 5px 10px;
                background-color: #ffffff;
                border-radius: 6px;
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
                color: #2A2A2A;
                transition: color 0.2s;
                cursor: pointer;
                &:hover{
                    color: #f1002e;
                }
            }
        }
        .all_towns{
            margin-top: 30px;
            font-size: 13px;
            .list_title{
                text-transform: uppercase;
                font-family: 'prox_sb';
                user-select: none;
                margin-bottom: 10px;
            }
            .districts, 
            .regions,
            .towns{
                height: 300px;
                overflow-y: auto;
                background-color: #ffffff;
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
                padding: 10px 20px;
                border-radius: 6px;
                .item{
                    // font-family: 'prox_sb';
                    padding: 5px 0;
                    cursor: pointer;
                    color: #2A2A2A;
                    transition: color 0.2s;
                    &:hover{
                        color: #f1002e;
                    }
                    &.active{
                        font-family: 'prox_sb';
                        color: #f1002e;
                    }
                }
            }
        }
        .btn-close{
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
        }
    }
}

.region_panel{
    position: fixed;
    top: 50px;
    left: 120px;
    // transform: translateX(-50%) translateY(-50%) !important;
    padding: 40px 40px 30px 40px;
    flex-wrap: nowrap;
    width: 300px;
    z-index: 1200;
    height: auto;
    bottom: unset;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    &.show{
        display: flex;
    }
    .subtext{
        color: #999999;
        font-size: 12px;
        font-family: 'prox_sb';
        user-select: none;
        text-transform: none;
    }
    .val{
        color: #2A2A2A;
        font-size: 15px;
        font-family: 'prox_bo';
        cursor: default;
    }
    .region_btns{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        button, 
        a{
            font-size: 13px;
            padding: 8px 12px;
            white-space: nowrap;
        }
        button{
            background-color: #f1002e;
            border: none;
            margin-right: 5px;
        }
    }
}

@media (max-width:575.98px){
    .header{
        padding: 12px 12px 0 12px;
        .navbar{
            height: 60px;
            padding-left: 10px;
            padding-right: 5px;
        }
    }
    .region_panel{
        // top: unset;
        top: 12px;
        left: 12px;
        right: 12px;
        width: auto;
    }
}

@media (max-width:991.98px) {
    #regionModal{
        .modal-body{
            padding: 40px 20px;
            .all_towns{
                display: none;
            }
        }
    }
}

@media (max-width: 1260px) {
    .leftbar_helper{
        display: none;
    }
    .main{
        width: 100%;
        // transform: translateX(0px);
    }
    .mask{
        z-index: 500;
    }
}

@media (max-width: 1260px) {
    .main{
        margin-top: 50px;
    }
}
