.regions{
    .regions_list{
        margin-top: 40px;
        & > div {
            animation: fade 0.3s;
        }
        .regions_list_item{
            padding: 10px 0;
            cursor: pointer;
            color: #2a2a2a;
            transition: all 0.2s;
            &:hover{
                color: #f1002e;
            }
        }
    }
}