.search{
    h1{
        user-select: none;
    }
    .search_form{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        input{
            display: flex;
            flex-grow: 1;
            margin-bottom: 0;
            align-self: stretch;
        }
        button{
            display: flex;
            margin-left: 10px;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            padding: 18px 36px;
            background: #2a2a2a;
            cursor: pointer;
            transition: all 0.2s;
            border-radius: 2px;
            align-self: stretch;
            &:hover{
                background: #3a3a3a;
            }
            .icon{
                user-select: none;
                width: 12px;
                margin-right: 6px;
            }
            .text{
                color: #ffffff;
                user-select: none;
                text-transform: uppercase;
                font-size: 12px;
                font-family: 'rubik_med';
            }
        }
    }
}