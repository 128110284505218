.change_password{
    .change_password_form{
        .field{
            margin-bottom: 20px;
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
        }
        .change_password_form_btn{
            padding: 0 15px;
            .btn{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    background: #333333;
                }
            }
        }
    }
}

@media (max-width: 539px) {
    .change_password{
        .change_password_form{
            .field{
                margin-bottom: 10px;
            }
        }
    }
}