.contacts{
    .place{
        margin-bottom: 50px;
        .item{
            font-size: 18px;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            .subtext{
                color: #999999;
                user-select: none;
                font-family: 'rubik_light';
            }
            .text{
                cursor: default;
                color: #2a2a2a;
                text-decoration: none;
            }
            a.text{
                transition: all 0.2s;
                cursor: pointer;
                &:hover{
                    color: #f1002e;
                }
            }
        }
        .item_2{
            
            padding: 5px 0;
            .subtext{
                color: #999999;
                user-select: none;
                font-family: 'rubik_light';
            }
            .text{
                cursor: default;
                color: #2a2a2a;
                text-decoration: none;
            }
        }
    }
    .contact_card{
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 50px;
        border-bottom: 1px solid #eeeeee;
        width: 100%;
        .pos{
            text-transform: uppercase;
            margin-bottom: 5px;
            cursor: default;
        }
        .name{
            font-size: 15px;
            font-family: 'rubik_med';
            cursor: default;
            margin-bottom: 15px;
        }
        .subinfo{
            color: #999999;
            font-size: 12px;
            margin-bottom: 15px;
            cursor: default;
        }
        a{
            color: #2a2a2a;
            transition: all 0.2s;
            text-decoration: none;
            margin-bottom: 5px;
            &:hover{
                color: #f1002e;
            }
            &.email{
                margin-top: 10px;
            }
        }
    }
}