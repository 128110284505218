.card_any{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 30px;
    color: #ffffff;
    animation: fadeup 0.2s;
    text-decoration: none;
    .image{
        width: 100%;
        overflow: hidden;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            display: flex;
            object-fit: cover;
            width: 100%;
            height: 154px;
            user-select: none;
            transition: all 0.4s;
            transform: scale(1);
        }
    }
    .item_info{
        display: flex;
        background: #2a2a2a;
        align-items: center;
        justify-content: center;
        padding: 15px 20px;
        flex-grow: 1;
        min-height: 60px;
        .item_name{
            color: #cccccc;
            transition: color 0.2s;
            text-transform: uppercase;
            font-size: 13px;
            user-select: none;
            text-align: center;
            line-height: normal;
            font-weight: 500;
            transition: color 0.2s;
        }
    }

    &:hover{
        .image{
            img{
                transform: scale(1.1);
            }
        }
        .item_info{
            .item_name{
                color: #ffffff;
            }
        }
    }
}