.basket{
    .basket_without_prods,
    .basket_with_prods{
        display: none;
        &.show{
            display: block;
        }
    }
    .cart_title_with_count span{
        color: #999999;
    }



    .basket_empty{

    }
    .basket_list{
        border: 1px solid #e5e5e5;
        .basket_list_item{
            display: flex;
            border-bottom: 1px solid #e5e5e5;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            &:last-child{
                border-bottom: none;
            }
            & > div.d-flex {
                flex-grow: 1;
            }

            .item_image{
                display: flex;
                width: 140px;
                min-width: 140px;
                height: 120px;
                align-items: center;
                justify-content: center;
                padding: 10px 20px;
                img{
                    user-select: none;
                    object-fit: scale-down;
                    width: 100%;
                    height: 100%;
                    display: flex;
                }
            }
            .item_info{
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                // width: 100%;
                align-items: flex-start;
                .item_info_name{
                    display: flex;
                    font-size: 18px;
                    flex-wrap: wrap;
                    color: #2a2a2a;
                    text-decoration: none;
                    transition: color 0.2s;
                    font-family: 'rubik_light';
                    margin-top: 20px;
                    &:hover{
                        color: #f1002e;
                    }
                }
                .item_info_props{
                    display: flex;
                    flex-direction: column;
                    .item_info_props_el{
                        display: flex;
                        flex-direction: row;
                        margin-top: 5px;
                        flex-wrap: wrap;
                        &:first-child{
                            margin-top: 15px;
                        }
                        &:last-child{
                            margin-bottom: 20px;
                        }
                        .subtext{
                            color: #999999;
                            user-select: none;
                            font-size: 12px;
                            margin-right: 6px;
                        }
                        .value{
                            font-family: 'rubik_med';
                            font-size: 12px;
                            margin-right: 30px;
                            cursor: default;
                        }
                    }
                }
            }
            .item_price{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 140px;
                min-width: 140px;
                margin-right: 20px;
                .subtext{
                    color: #999999;
                    font-size: 12px;
                    user-select: none;
                }
                .value{
                    font-family: 'rubik_med';
                    cursor: default;
                    color: #2a2a2a;
                }
            }
            .item_controls{
                display: flex;
                flex-direction: row;
                align-items: center;
                .item_qty{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    // width: 150px;
                    // min-width: 150px;
                    justify-content: center;
                    .counter_wrap{
                        display: flex;
                        align-items: center;
                        width: 150px;
                        min-width: 150px;
                        justify-content: flex-start;
                        .counter{
                            border: 1px solid #eee;
                            padding: 0;
                            display: flex;
                            height: max-content;
                            align-items: stretch;
                            background: #ffffff;
                            input{
                                padding: 10px;
                                background: transparent;
                                width: 80px;
                                font-size: 12px;
                                font-family: 'rubik_med';
                                text-align: center;
                                &:focus::placeholder{
                                    opacity: 0;
                                }
                            }
                            .control_btns{
                                display: flex;
                                flex-direction: column;
                                border-left: 1px solid #eeeeee;
                                flex-grow: 1;
                                & > div{
                                    width: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-grow: 1;
                                    cursor: pointer;
                                    user-select: none;
                                    &:first-child{
                                        border-bottom: 1px solid #eeeeee;
                                    }
                                }
                            }
                        }
                        .units{
                            color: #999999;
                            font-size: 12px;
                            user-select: none;
                            margin-left: 10px;
                        }
                    }
                    .unitconvert{
                        display: flex;
                        margin-top: 10px;
                        align-items: center;
                        .unitconvert_item{
                            display: flex;
                            justify-content: center;
                            padding: 5px;
                            flex-wrap: nowrap;
                            align-items: center;
                            cursor: pointer;
                            margin-right: 5px;
                            font-size: 12px;
                            color: #2a2a2a;
                            background: #eeeeee;
                            width: 80px;
                            transition: all 0.2s;
                            &:last-child{
                                margin-right: 0px;
                            }
                            &:hover{
                                color: #f1002e;
                            }
                            .value{
                                font-family: 'rubik_med';
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .item_summ{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    width: 160px;
                    min-width: 160px;
                    margin-left: 10px;
                    .subtext{
                        color: #999999;
                        font-size: 12px;
                        user-select: none;
                    }
                    .value{
                        font-size: 18px;
                        font-family: 'rubik_med';
                        cursor: default;
                    }
                }
            }
            
            .item_del{
                width: 24px;
                min-width: 24px;
                height: 24px;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
                margin-left: 30px;
                img{
                    user-select: none;
                    display: flex;
                    
                }
            }
        }
    }


    .basket_msg{
        margin-top: 50px;
        label{
            padding-bottom: 5px;
            font-family: 'rubik_med';
            color: #999999;
            text-transform: uppercase;
            font-size: 10px;
            display: flex;
        }
    }

    .basket_total{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        .wrap{
            display: flex;
            align-items: center;
        }
        .basket_total_info{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 40px;
            .subtext{
                color: #999999;
                font-size: 12px;
                user-select: none;
            }
            .value{
                color: #2a2a2a;
                font-size: 18px;
                font-family: 'rubik_med';
                cursor: default;
            }
        }
        .order_apply{
            background: #2a2a2a;
            padding: 20px 50px;
            color: #ffffff;
            text-transform: uppercase;
            user-select: none;
            cursor: pointer;
            font-family: 'rubik_med';
            border-radius: 2px;
            font-size: 12px;
            transition: all 0.2s;
            &:hover{
                background: #393939;
            }
        }
        .basket_save{
            display: none;
            background: #999999;
            padding: 10px 30px;
            color: #ffffff;
            text-transform: uppercase;
            user-select: none;
            cursor: pointer;
            font-family: 'rubik_med';
            border-radius: 2px;
            font-size: 12px;
            transition: all 0.2s;
            &:hover{
                background: #393939;
            }
            &.show{
                display: flex;
            }
        }
    }
}


@media (max-width:1239px) {
    .basket{
        .basket_list{
            .basket_list_item{
                position: relative;
                flex-direction: column;
                align-items: stretch;
                .item_controls{
                    justify-content: space-between;
                    padding: 10px 20px;
                    flex-wrap: wrap;
                    .item_price{
                        min-width: unset;
                        width: unset;
                        margin-right: 0;
                        flex-grow: 1;
                    }
                    .item_qty{
                        margin: 3px 10px 0; 
                    }
                    .item_summ{
                        // min-width: unset;
                        width: unset;
                        margin-left: 0;
                    }
                }
                .item_del{
                    margin-left: 0;
                    margin-right: 0;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .basket{
        .basket_list{
            .basket_list_item{

            }
        }
        .basket_total{
            flex-direction: column-reverse;
            align-items: flex-end;
            margin-top: 20px;
            .basket_save{
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width:539px) {
    .basket{
        .basket_list{
            .basket_list_item{
                .item_info{
                    padding-right: 20px;
                    .item_info_props{
                        .item_info_props_el{
                            flex-direction: column;
                        }
                        .item_info_props_el:nth-child(2){
                            display: none;
                        }
                    }
                }
                .item_controls{
                    .item_price{
                        flex-direction: row;
                        width: 100%;
                        margin-bottom: 10px;
                        .subtext{
                            margin-right: 6px;
                        }
                    }
                    .item_qty{
                        margin: 3px 0 0 0;
                        min-width: unset;
                        width: unset;
                        .units{
                            display: none;
                        }
                    }
                    .item_summ{
                        min-width: unset;
                    }
                }
            }
        }
        .basket_total{
            align-items: stretch;
            .wrap{
                .basket_total_info{
                    margin-right: 0;
                    align-items: center;
                    margin-bottom: 30px;
                }
                flex-direction: column;
            }
        }
    }
}