.trans_list{

    .balance{
        display: flex;
        background: #f6f6f6;
        border-radius: 3px;
        padding: 30px;
        margin-bottom: 60px;
        width: 100%;
        max-width: 420px;
        flex-direction: row;
        align-items: center;
        .balance_icon{
            background: #ffffff;
            width: 100px;
            height: 100px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            img{
                display: flex;
                user-select: none;
                width: 40px;
            }
        }
        .balance_data{
            display: flex;
            flex-direction: column;
            .current{
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                .subtext{
                    user-select: none;
                    color: #999999;
                    font-size: 12px;
                }
                .value{
                    color: #00a316;
                    font-size: 24px;
                    font-family: 'rubik_med';
                    cursor: default;
                }
            }
            .all_pay,
            .all_income{
                display: flex;
                margin-bottom: 3px;
                font-size: 12px;
                flex-wrap: wrap;
                .subtext{
                    margin-right: 5px;
                    color: #999999;
                    user-select: none;
                }
                .value{
                    font-family: 'rubik_med';
                    color: #2a2a2a;
                    cursor: default;
                }
            }
        }
    }

    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }

    .search{
        justify-content: space-between;
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
        align-items: flex-end;
        margin-bottom: 30px;
        flex-wrap: wrap;
        .search_field{
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &.name{
                flex-grow: 1;
            }
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
            input{
                height: 46px;
            }
            button{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                &:hover{
                    background: #333333;
                }
            }
        }
    }

    table{
        tbody{
            tr{
                td{
                    &.doc{
                        .doc_number{
                            font-family: 'rubik_med';
                        }
                        .doc_date{
                            font-size: 12px;
                            color: #666666;
                        }
                    }
                    &.amount{
                        text-align: right;
                        font-family: 'rubik_med';
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .trans_list{
        .search{
            .search_field{
                &.name{
                    width: 100%;
                }
                &.date{
                    flex-grow: 0.5;
                }
                
            }
        }
    }
}

@media (max-width:539px) {
    .trans_list{
        .search{
            .search_field{
                &.name{
                    min-width: unset;
                }
                &.date, &.btn{
                    flex-grow: unset;
                    width: 100%;
                }
            }
        }
    }
}