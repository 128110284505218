.card_producer{
    color: #2a2a2a;
    text-decoration: none;
    font-family: 'rubik_med';
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-bottom: 30px;
    // border: 1px solid #cccccc;
    .image{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        overflow: hidden;
        img{
            width: 100%;
            object-fit: scale-down;
            display: flex;
            user-select: none;
            transition: opacity 0.2s;
            opacity: 1;
        }
    }
    .producer_info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        .producer_name{
            text-transform: uppercase;
            font-size: 13px;
            padding: 5px 0;
            color: #2a2a2a;
            margin-bottom: 10px;
            transition: color 0.2s;
            display: block;
            text-align: center;
        }
    }
    &:hover{
        .image{
            img{
                opacity: 0.8;
            }
        }
        .producer_info{
            .producer_name{
                color: #f1002e;
            }
        }
    }
}