// CHAT -- start
.desktop_chat_btn{
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 100;
    background-color: #f1002e;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    transition: background-color 0.2s;
    &:hover{
        background-color: #d90028;
    }
    svg{
        width: 24px;
    }
}

.chat_panel{
    overflow-x: hidden;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    .btn-close{
        position: fixed;
        top: 20px;
        right: 20px;
    }
    .massage_list_wrap{
        display: flex;
        flex-grow: 1;
        position: relative;
        width: calc(100% + 20px);
        height: 100%;
        overflow-y: auto;
        .massage_list{
            height: auto;  
            flex-grow: 1;          
            padding: 0 30px 5px 10px;
            // min-height: calc(100vh - 120px);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            box-sizing: border-box;
            .msg{
                border-radius: 10px;
                font-size: 13px;
                padding: 5px 10px;
                max-width: 100%;
                margin: 1px 0;
                cursor: default;
                &.in{
                    background-color: #f6f6f6;
                    align-self: flex-start;
                }
                &.out{
                    background-color: #ffcdd6;
                    align-self: flex-end;
                }

            }
        }
    }
    .send_form{
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        position: relative;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #f4f4f4;
        padding: 10px;
        #msg_field{
            min-height: 40px;
            max-width: 100%;
            height: auto;
            background-color: #ffffff;
            border-radius: 20px;
            display: flex;
            flex-grow: 1;
            padding: 10px 15px;
            overflow: hidden;
        }
        button{
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: #f1002e;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            flex-shrink: 0;
            border: none;
            svg{
                width: 20px;
                margin-left: 3px;
            }
        }
    }
}

// CHAT -- end