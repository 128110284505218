.category{

    .category_title{
        display: flex;
        align-items: center;
        margin: 50px 0 30px 0;
        h1{
            margin: 0;
            display: flex;
        }
        .category_title_qty{
            color: #999999;
            font-size: 16px;
            display: flex;
            user-select: none;
            &::before{
                content: '-';
                margin: 0 10px;
                display: flex;
            }
        }
    }

    .selected_props{
        display: flex;
        flex-direction: column;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        padding-top: 15px;
        padding-bottom: 10px;
        margin-bottom: 30px;
        .selected_props_title{
            font-family: 'roboto_med';
            display: flex;
            margin-bottom: 15px;
            align-items: flex-end;
            .text{
                user-select: none;
                color: #2a2a2a;
                margin-right: 30px;
            }
            .reset_btn{
                color: #999999;
                user-select: none;
                cursor: pointer;
                transition: color 0.2s;
                font-size: 11px;
                font-family: 'roboto_reg';
                &:hover{
                    color: #666666;
                }
            }
        }
        .selected_props_list{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .item{
                display: flex;
                align-items: center;
                padding: 10px 20px;
                margin-right: 20px;
                margin-bottom: 10px;
                background: #f6f6f6;
                cursor: pointer;
                transition: all 0.2s;
                font-size: 11px;
                font-family: 'roboto_med';
                &:hover{
                    background: #fafafa;
                    .text{
                        color: #666666;
                    }
                    .icon{
                        transform: rotate(180deg);
                    }
                }
                .text{
                    color: #999999;
                    margin-right: 5px;
                    transition: color 0.2s;
                }
                .icon{
                    transition: transform 0.2s;
                    transform: rotate(0deg);
                    user-select: none;
                }
            }
        }
    }

    .list_controls{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f6f6f6;
        margin-bottom: 30px;
        padding: 10px 40px;
        .sort{
            display: flex;
            align-items: center;
            .subtext{
                color: #2a2a2a;
                user-select: none;
            }
            .values{
                display: flex;
                align-items: center;
                .value{
                    padding: 10px;
                    margin-left: 10px;
                    cursor: pointer;
                    color: #999999;
                    transition: color 0.2s;
                    user-select: none;
                    &:hover{
                        color: #2a2a2a;
                    }
                    &.active{
                        color: #f1002e;
                        cursor: default;
                    }
                }
            }
        }
        .filter{
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px;
            &:hover{
                .text{
                    color: #2a2a2a;
                }
            }
            img{
                margin-right: 6px;
                user-select: none;
                display: flex;
            }
            .text{
                transition: color 0.2s;
                display: flex;
                user-select: none;
                color: #999999;
            }
        }
    }

    .card_list{
        // margin-top: 50px;
    }
}



// .filter_panel{
//     position: fixed;
//     top: 80px;
//     right: 0px;
//     width: 100%;
//     max-width: 360px;
//     height: 100%;
//     background: #eee;
//     z-index: 300;
//     overflow: hidden;
//     // display: none;
//     animation: fadeFilter 0.4s;
//     .filter_panel_wrap{
//         position: absolute;
//         overflow-y: auto;
//         overflow-x: hidden;
//         max-height: calc(100% - 80px);
//         width: calc(100% + 20px);
//         padding: 40px;
//         .title{
//             font-family: 'roboto_bold';
//             color: #2a2a2a;
//             text-transform: uppercase;
//             user-select: none;
//             margin-bottom: 40px;
//         }
//         .item{
//             position: relative;
//             margin-bottom: 20px;
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             .fields{
//                 display: flex;
//                 input{
//                     width: 100%;
//                     border: 1px solid;
//                     border-color: transparent;
//                     border-radius: 0px;
//                     background: #f6f6f6;
//                     padding: 15px 30px;
//                     transition: all 0.2s;
//                     font-size: 13px;
//                     font-family: 'roboto_med';
//                     max-width: 360px;
//                     text-align: center;
//                     margin-right: 30px;
//                     &:focus{
//                         background: #ffffff;
//                         border-color: #cccccc;
//                     }
//                     &::placeholder{
//                         font-style: italic;
//                         font-family: 'roboto_reg';
//                         opacity: 1;
//                         transition: opacity 0.2s;
//                     }
//                     &:focus::placeholder{
//                         opacity: 0;
//                     }
//                 }
//             }
//             .item_title{
//                 font-family: 'roboto_med';
//                 cursor: default;
//                 margin-bottom: 10px;
//                 font-size: 14px;
//                 &::first-letter{
//                     text-transform: uppercase;
//                 }
//             }
//             .item_list{
//                 margin-bottom: 20px;
//                 max-height: 180px;
//                 overflow-y: auto;
//                 &.shortlist{
//                     max-height: none;
//                     .item_list_input{
//                         display: none;
//                         &:nth-child(-n+8){
//                             display: flex;
//                         }
//                     }
//                 }
//                 .item_list_input{
//                     display: flex;
//                     align-items: center;
//                     position: relative;
//                     .show_btn{
//                         width: 120px;
//                         height: 5px;
//                         background: brown;
//                         position: absolute;
//                         left: 100%;
//                         top: 50%;
//                         transform: translateY(-50%);
//                     }
                    
//                     // input{
//                     //     position: absolute;
//                     //     z-index: -1;
//                     //     opacity: 0;
//                     // }
//                     // input + label{
//                     //     user-select: none;
//                     //     display: block;
//                     //     position: relative;
//                     //     padding: 4px 2px 4px 24px;
//                     //     cursor: pointer;
//                     //     transition: all 0.2s;
//                     //     color: #2a2a2a;
//                     //     &:hover{
//                     //         color: #f1002e;
//                     //     }
//                     //     &::first-letter{
//                     //         text-transform: uppercase;
//                     //     }
//                     //     &::before{
//                     //         content: '';
//                     //         display: block;
//                     //         width: 100%;
//                     //         position: absolute;
//                     //         left: 0;
//                     //         top: 50%;
//                     //         transform: translateY(-50%);
//                     //         width: 18px;
//                     //         height: 18px;
//                     //         background: #ffffff;
//                     //         border-radius: 2px;

//                     //     }
//                     // }
//                 }
//             }
//         }
//     }
// }

@keyframes fadeFilter {
    from{
        opacity: 1;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}