.invoice_deteil{
    .invoice_deteil_info{
        margin-bottom: 50px;
        .info_item{
            background: #f6f6f6;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            padding: 30px 50px;
            margin-bottom: 20px;
            .subtext{
                color: #999999;
                user-select: none;
                font-size: 10px;
                font-family: 'rubik_med';
                text-transform: uppercase;
            }
            .value{
                font-size: 21px;
                color: #2a2a2a;
                cursor: default;
                font-family: 'rubik_light';
                .text{
                    font-size: 21px;
                    color: #2a2a2a;
                    cursor: default;
                    font-family: 'rubik_light';
                }
                a.text{
                    cursor: pointer;
                    transition: all 0.2s;
                    text-decoration: none;
                    &:hover{
                        color: #f1002e;
                    }
                }
            }
        }
    }

    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }

    table{
        tbody{
            tr{
                td.product_info{
                    .product_name{
                        display: flex;
                        font-size: 15px;
                        color: #2a2a2a;
                        text-decoration: none;
                        transition: color 0.2s;
                        font-family: 'rubik_light';
                        &:hover{
                            color: #f1002e;
                        }
                    }
                    .product_subname{
                        color: #999999;
                        cursor: default;
                        span{
                            color: #2a2a2a;
                            font-family: 'rubik_med';
                            margin-right: 15px;
                        }
                    }
                }
                td.price{
                    text-align: right;
                    cursor: default;
                }
                td.amount{
                    font-family: 'rubik_med';
                    text-align: right;
                    cursor: default;
                }
            }
        }
    }
}

@media (max-width: 1239px) {
    .invoice_deteil{
        .invoice_deteil_info{
            margin-bottom: 30px;
            .info_item{
                padding: 20px 40px;
            }
        }
    }
}