.login{
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .login_form{
        width: 100%;
        max-width: 360px;
        .login_form_title{
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
            text-align: center;
            text-transform: uppercase;
            user-select: none;
        }
        .login_error{
            text-align: center;
            color: #f1002e;
            user-select: none;
            // margin-bottom: 30px;
        }
        ul{
            list-style: none;
            li{
                text-align: center;
                color: #f1002e;
                user-select: none;
                margin-top: 10px;
                &:first-child{
                    margin-top: 0;
                }
            }
        }
        .login_form_field{
            &.mt_30{
                margin-top: 30px;
            }
            margin-bottom: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            input{
                width: 100%;
                border: 1px solid;
                border-color: transparent;
                border-radius: 0px;
                background: #f6f6f6;
                padding: 15px 30px;
                transition: all 0.2s;
                font-size: 13px;
                font-family: 'rubik_med';
                &:focus{
                    background: #ffffff;
                    border-color: #cccccc;
                }
                &::placeholder{
                    font-style: italic;
                    font-family: 'rubik_reg';
                    opacity: 1;
                    transition: opacity 0.2s;
                }
                &:focus::placeholder{
                    opacity: 0;
                }
            }
            .forget_pwd{
                color: #999999;
                margin: 5px 0 0 auto;
                font-size: 12px;
                display: inline-flex;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    color: #f1002e; 
                }
            }
        }
        .login_form_btn {
            input{
                font-family: 'rubik_med';
                cursor: pointer;
                margin: 20px auto 0;
                color: #ffffff;
                font-size: 15px;
                padding: 15px 40px;
                background: #2A2A2A;
                display: flex;
                transition: all 0.2s;
                &:hover{
                    background: #393939;
                }                
            }
        }
        .go_to_reg{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
            .subtext{
                user-select: none;
                color: #999999;
                font-size: 12px;
            }
            .reg_link{
                color: #2A2A2A;
                font-family: 'rubik_med';
                text-decoration: none;
                transition: all 0.2s;
                &:hover{
                    color: #f1002e;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .login{
        min-height: calc(100vh - 50px);
        margin-bottom: 120px;
    }    
}