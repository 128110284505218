.orders{

    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }

    .search{
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: flex-end;
        margin-bottom: 30px;
        .search_field{
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &.name{
                flex-grow: 1;
            }
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
            input{
                height: 46px;
            }
            .search_btn{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    background: #333333;
                }
            }
        }
    }

    table{
        tbody{
            tr{
                cursor: pointer;
                td{
                    &.info{
                        flex-direction: column;
                        width: 100%;
                        .number{
                            display: flex;
                            align-items: flex-end;
                            margin-bottom: 5px;
                            .subtext{
                                color: #999999;
                                user-select: none;
                                margin-right: 6px;
                                white-space: nowrap;
                            }
                            .value{
                                font-family: 'rubik_med';
                                text-transform: uppercase;
                                white-space: nowrap;
                            }
                        }
                        .date{
                            display: flex;
                            align-items: center;
                            img{
                                margin-right: 6px;
                                user-select: none;
                            }
                            .value{
                                font-size: 12px;
                                color: #999999;
                            }
                        }
                        span{
                            align-self: flex-start;
                        }
                    }
                    &.total,
                    &.payment{
                        text-align: right;
                        font-family: 'rubik_med';
                    }
                }
            }
        }
    }
    // .orders_table{
    //     display: flex;
    //     flex-direction: column;
    //     width: 100%;
    //     border: 1px solid #e5e5e5;
    //     .orders_table_head,
    //     .orders_table_row{
    //         display: flex;
    //         flex-direction: row;
    //         align-items: stretch;
    //         justify-content: space-between;
    //         border-bottom: 1px solid #e5e5e5;
    //         flex-wrap: nowrap;
    //         &:last-child{
    //             border-bottom: none;
    //         }
    //         .item{
    //             border-right: 1px solid #e5e5e5;
    //             display: flex;
    //             padding: 20px 30px;
    //             &:last-child{
    //                 border-right: none;
    //             }
    //             &.numb{
    //                 width: 100%;
    //                 flex-direction: column;
    //                 .number{
    //                     display: flex;
    //                     align-items: flex-end;
    //                     margin-bottom: 5px;
    //                     .subtext{
    //                         color: #999999;
    //                         user-select: none;
    //                         margin-right: 6px;
    //                     }
    //                     .value{
    //                         font-family: 'rubik_med';
    //                         text-transform: uppercase;
    //                     }
    //                 }
    //                 .date{
    //                     display: flex;
    //                     align-items: center;
    //                     img{
    //                         margin-right: 6px;
    //                         user-select: none;
    //                     }
    //                     .value{
    //                         font-size: 12px;
    //                         color: #999999;
    //                     }

    //                 }
    //                 span{
    //                     align-self: flex-start;
    //                 }
    //             }
    //             &.status{
    //                 width: 200px;
    //             }
    //             &.payment{
    //                 width: 200px;
    //                 justify-content: flex-end;
    //                 font-family: 'rubik_med';
    //             }
    //             span{
    //                 display: flex;
    //                 align-self: center;
    //             }
    //         }
    //     }
    //     .orders_table_head{
    //         background: #f1f1f1;
    //         font-family: 'rubik_med';
    //         .item{
    //             padding: 30px;
    //             .text{
    //                 user-select: none;
    //             }
    //         }
    //     }
    //     .orders_table_row{
    //         transition: all 0.2s;
    //         cursor: pointer;
    //         &:hover{
    //             background: #f9f9f9;
    //         }
    //     }
    // }
}

@media (max-width:539px) {
    .orders{
        .search{
            .search_field{
                width: 100%;
            }
        }
    }
}