.navbar{
    margin-bottom: 80px;
    .navbar_top{
        border-bottom: 1px solid #e5e5e5;
        background: #ffffff;
        .navbar_top_wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .linklist{
                display: flex;
                align-items: center;
                .link{
                    color: #666666;
                    text-decoration: none;
                    transition: color 0.2s;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    line-height: 16px;
                    padding-left: 20px;
                    padding-right: 20px;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    &:hover{
                        color: #f1002e;
                    }
                    .icon{
                        display: flex;
                        user-select: none;
                        margin-right: 8px;
                    }
                }
                &.right .link{
                    user-select: none;
                }
            }
        }
    }
    .navbar_mid{
        border-bottom: 1px solid #e5e5e5;
        background: #ffffff;
        .navbar_mid_wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 120px;
            .logo{
                display: flex;
                img{
                    display: flex;
                    user-select: none;
                    &.text{
                        margin-left: 6px;
                    }
                }
            }
            .control_btns{
                display: flex;
                align-items: center;
                .item,
                .dropdown .dropdown_btn{
                    display: flex;
                    align-items: center;
                    color: #2a2a2a;
                    font-size: 14px;
                    // font-family: 'rubik_med';
                    text-decoration: none;
                    transition: color 0.2s;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-right: 40px;
                    padding-left: 0px;
                    cursor: pointer;
                    &:before{
                        content: '';
                        display: flex;
                        width: 1px;
                        height: 32px;
                        background: #e5e5e5;
                        margin-right: 40px;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    &:first-child:before{
                        display: none;
                    }
                    &:hover{
                        color: #f1002e;
                        .icon{
                            filter: grayscale(0);
                            opacity: 1;
                        }
                        // .text .customer{
                        //     color: #f1002e;
                        // }
                    }
                    .icon{
                        user-select: none;
                        display: flex;
                        filter: grayscale(100%);
                        opacity: 0.8;
                        transition: all 0.2s;
                    }
                    .text{
                        user-select: none;
                        margin-left: 8px;
                        .user{

                        }
                        .customer, 
                        .subtext {
                            transition: all 0.2s;
                            color: #999999;
                            font-size: 10px;
                            font-family: 'rubik_med';
                        }
                    }
                }
                .item.info_menu_trigger,
                .item.user_menu_trigger{
                    display: none;
                    .fixed_container{
                        position: fixed;
                        left: 0;
                        top: 0;
                        z-index: 3000;
                        width: 0px;
                        height: 0px;
                        &.show{
                            .fixed_panel{
                                transform: translateX(0%);
                                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
                                
                            }
                            .menu_mask{
                                display: block;
                            }
                        }
                        .fixed_panel{
                            position: fixed;
                            left: 0;
                            transform: translateX(-100%);
                            transition: all 0.3s;
                            top: 0;
                            z-index: 10;
                            width: 320px;
                            height: 100vh;
                            overflow: hidden;
                            padding: 0;
                            background: #ffffff;
                            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
                            .menu_body{
                                width: 340px;
                                max-height: 100vh;
                                position: absolute;
                                padding: 40px 60px 40px 40px;
                                overflow-y: auto;
                                overflow-x: hidden;
                                .link_list{
                                    display: flex;
                                    flex-direction: column;
                                    border-bottom: 1px solid #e5e5e5;
                                    padding-bottom: 30px;
                                    .title{
                                        font-family: 'rubik_med';
                                        color: #2a2a2a;
                                        text-transform: uppercase;
                                        user-select: none;
                                        margin-bottom: 30px;
                                    }
                                    .link{
                                        text-decoration: none;
                                        color: #2a2a2a;
                                        padding: 6px 0;
                                    }
                                }
                                .contacts{
                                    display: flex;
                                    flex-direction: column;
                                    border-bottom: 1px solid #e5e5e5;
                                    padding-bottom: 30px;
                                    .title{
                                        font-family: 'rubik_med';
                                        color: #2a2a2a;
                                        text-transform: uppercase;
                                        user-select: none;
                                        margin-bottom: 30px;
                                        margin-top: 30px;
                                    }
                                    .contacts_item{
                                        margin-bottom: 10px;
                                        .subtext{
                                            color: #999999;
                                            font-size: 10px;
                                            text-transform: uppercase;
                                            font-family: 'rubik_med';
                                            user-select: none;
                                        }
                                        .value{
                                            text-decoration: none;
                                            color: #2a2a2a;
                                            padding: 6px 0;
                                        }
                                    }
                                }
                                .region{
                                    text-decoration: none;
                                    margin-top: 30px;
                                    display: flex;
                                    color: #2a2a2a;
                                    img{
                                        margin-right: 8px;
                                    }
                                }
                            }
                            .close_icon{
                                position: absolute;
                                right: 20px;
                                top: 20px;
                                cursor: pointer;
                                user-select: none;
                            }
                        }
                        .menu_mask{
                            position: fixed;
                            left: 0;
                            top: 0;
                            z-index: 5;
                            width: 100vw;
                            height: 100vh;
                            background: rgba(0,0,0,0.7);
                            cursor: pointer;
                            display: none;
                            animation: fade 0.3s;
                        }
                    }
                }
                .dropdown.lk{
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    position: relative;
                    &:before{
                        content: '';
                        display: flex;
                        width: 1px;
                        height: 32px;
                        background: #e5e5e5;
                        margin-right: 40px;
                    }
                    .text .user{
                        display: flex;
                    }
                    .text .user .arrow{
                        transform: rotate(90deg);
                        filter: grayscale(100%);
                        display: flex;
                        user-select: none;
                        margin-left: 10px;
                        transition: all 0.2s;
                    }
                    & .dropdown_btn:hover .text .user .arrow{
                        filter: grayscale(0);
                    }
                    .dropdown_body{
                        position: absolute;
                        top: 100%;
                        right: 0;
                        background: #ffffff;
                        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
                        display: none;
                        flex-direction: column;
                        padding: 30px 40px;
                        margin-top: 10px;
                        animation: fadeup 0.3s;
                        z-index: 500;
                        &.show{
                            display: flex;
                        }                    
                        .href{
                            white-space: nowrap;
                            text-decoration: none;
                            padding: 7px 10px;
                            color: #2A2A2A;
                            transition: color 0.2s;
                            &:hover{
                                color: #F1002E;
                            }
                        }
                    }
                }
            }
        }
    }
    .navbar_bot{
        background: #3a3a3a;
        // height: 50px;
        .navbar_bot_wrap{
            // display: flex;
            // align-items: center;
            .category_btn{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                background: #2a2a2a;
                padding: 20px 40px;
                cursor: pointer;
                opacity: 0.9;
                transition: all 0.2s;
                // margin-right: 15px;
                &:hover{
                    opacity: 1;
                }
                .text{
                    color: #ffffff;
                    user-select: none;
                    font-size: 15px;
                    // font-family: 'rubik_med';
                    text-transform: uppercase;
                }
                img{
                    user-select: none;
                    display: flex;
                }
            }
        }
        
    }
}

.mail_is_active{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background: #fff1f4;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    margin-bottom: 20px;
    .text{
        display: flex;
        align-items: center;
        margin-right: 20px;
        img{
            user-select: none;
            margin-right: 10px;
        }
        span{
            cursor: default;
            color: #2a2a2a;
        }
    }
    .activate_email{
        background: none;
        cursor: pointer;
        color: #2a2a2a;
        transition: color 0.2s;
        &:hover{
            color: #f1002e;
        }
    }
}

@media (max-width: 767px) {
    .navbar{
        .navbar_top{
            display: none;
            .navbar_top_wrap{
                .linklist.left{
                    justify-content: space-between;
                    width: 100%;
                }
                .linklist.right{
                    display: none;
                }
            }
        }
        .navbar_mid{
            .navbar_mid_wrap{
                height: 80px;
                
                .control_btns{
                    .item{
                        margin-left: 10px;
                        width: 40px;
                        height: 40px;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        &:last-child{
                            margin-right: -10px;
                        }
                        &::before{
                            display: none;
                        }
                        .text{
                            display: none;
                        }
                    }
                    .item.info_menu_trigger,
                    .item.user_menu_trigger{
                        display: flex;
                    }
                    .dropdown.lk{
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 539px) {
    .navbar{
        .navbar_mid{
            .navbar_mid_wrap{
                .logo{
                    .icon{
                        margin-left: 10px;
                    }
                    .text{
                        display: none;
                    }
                }
                .control_btns{
                    margin-right: 10px;
                }
            }
        }
    }
    .mail_is_active{
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 30px;
        .text{
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;
            img{
                margin-bottom: 10px;
            }
            span{

            }
        }
    }
    .activate_email{

    }
}