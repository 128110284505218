.nodes{
    #find-count-items{
        position: absolute;
        left: -9999px;
        top: 0;
        display: none;
        justify-content: center;
        align-items: center;
        min-width: 150px;
        z-index: 9999;
        background-color: #f1002e;
        color: #ffffff;
        padding: 20px 30px;
        margin-left: -20px;
        border-radius: 2px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        text-decoration: none;
        font-family: 'rubik_med';
        // opacity: 0;
        // visibility: hidden;
        transition: background-color 0.2s;
        &:hover{
            background-color: #d30027;
            &::before{
                background-color: #d30027;
            }
        }
        &::before{
            content: '';
            display: block;
            position: absolute;
            left: -3px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            background-color: #f1002e;
            width: 10px;
            height: 10px;
            border-radius: 1px;
            z-index: -1;
            transition: background-color 0.2s;
        }
        &.show{
            display: flex;
            // opacity: 1;
            // visibility: visible;
        }
        img {
            display: flex;
            width: 15px;
            height: 15px;
            animation: rotation .8s infinite linear;
        }
        .text{
            margin-right: 6px;
            // display: none;
        }
        .value{
            // display: none;
        }
    }
    .nodes_page_title{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 80px;
        border-bottom: 1px solid #e5e5e5;
        h1{
            padding: 0;
            text-transform: uppercase;
            font-family: 'rubik_light';
            font-size: 30px;
            font-weight: normal;
            margin: 0;
        }
        .bread{
            display: flex;
            align-items: center;
            margin-top: 10px;
            .bread_item{
                text-decoration: none;
                min-width: 20px;
                min-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999999;
                transition: color 0.2s;
                font-size: 12px;
                &:hover{
                    color: #f1002e;
                    img{
                        filter: grayscale(0%);
                        opacity: 1;
                    }
                }
                img{
                    display: flex;
                    user-select: none;
                    transition: all 0.2s;
                    filter: grayscale(100%);
                    opacity: 0.5;
                }
            }
            .devider{
                margin: auto 12px;
                user-select: none;
                &:last-child{
                    display: none;
                }
            }
        }
    }
    .view_controls{
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        .view_controls_btn{
            display: flex;
            width: 100%;
            align-items: center;
            padding: 10px;
            justify-content: space-between;
            filter: grayscale(100%);
            opacity: 0.7;
            transition: all 0.2s;
            color: #f1002e;
            font-family: 'rubik_med';
            font-size: 12px;
            cursor: pointer;
            text-decoration: none;
            &:hover{
                filter: grayscale(0%);
                opacity: 1;
            }
            img{
                user-select: none;
            }
            .text{
                user-select: none;
                text-transform: uppercase;
            }
            &.filter img.arrow{
                transform: rotate(180deg);
            }
            &.active{
                opacity: 1;
                &:hover{
                    filter: grayscale(100%);
                }
            }
        }
        .search{
            display: flex;
            align-items: center;
            width: 100%;
            border: 1px solid #e5e5e5;
            input{
                display: flex;
                align-items: center;
                flex-grow: 1;
                padding: 12px 30px;
                &::placeholder{
                    font-style: italic;
                    color: #999999;
                    transition: opacity 0.2s;
                    opacity: 1;
                    user-select: none;
                }
                &:focus{
                    &::placeholder{
                        opacity: 0;
                    }
                }
            }
            button{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 100%;
                background: transparent;
                border: none;
                filter: grayscale(100%);
                opacity: 0.4;
                transition: all 0.2s;
                cursor: pointer;
                img{
                    user-select: none;
                    display: flex;
                }
                &:hover{
                    filter: grayscale(0%);
                    opacity: 1;
                }
            }
        }
        
    }
    .items_view{
        margin-top: 60px;
        display: none;
        &.active{
            display: block;
            animation: fadeup 0.4s;
        }

        .filter_btn_mobile{
            display: none;
        }

        .selected_props{

        }

        .items_view_filter{
            .close_icon{
                display: none;
            }
            .filter_wrap{
                .filter_title{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    margin-bottom: 30px;
                    border-top: 1px solid #e5e5e5;
                    border-bottom: 1px solid #e5e5e5;
                    .icon{
                        display: flex;
                        user-select: none;
                        margin-right: 5px;
                        transform: scale(0.8);
                    }
                    .text{
                        text-transform: uppercase;
                        color: #2a2a2a;
                        user-select: none;
                        font-family: 'rubik_med';
                        font-size: 11px;
                    }
                }
                .category_item{
                    display: flex;
                    margin-bottom: 10px;
                    align-items: center;
                    color: #2a2a2a;
                    transition: color 0.2s;
                    text-decoration: none;
                    flex-wrap: nowrap;

                    &:hover{
                        color: #f1002e;
                    }
                    &:last-child{
                        margin-bottom: 40px;
                    }
                    &.any img{
                        width: 40px;
                        height: 40px;
                        border: 1px solid #e5e5e5;
                        object-fit: cover;
                        display: flex;
                        margin-right: 10px;
                        border-radius: 3px;
                    }
                    &.producer img{
                        width: 40px;
                        height: 40px;
                        object-fit: scale-down;
                        display: flex;
                        margin-right: 10px;
                        border-radius: 3px;
                        border: 1px solid #e5e5e5;
                    }
                    span{
                        white-space: normal;
                    }

                }

                .filter_list{
                    padding: 0 10px;

                    

                    .price{
                        .price_title{
                            font-family: 'rubik_med';
                            text-transform: uppercase;
                            user-select: none;
                            margin-bottom: 15px;
                        }
                        .price_fields{
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            position: relative;
                            margin-bottom: 10px;
                            input{
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                margin-bottom: 0;
                            }
                            .devider{
                                margin: 0 15px 0 15px;
                                user-select: none;
                                color: #999999;
                            }
                        }
                    }


                    .sizes_title{
                        font-family: 'rubik_med';
                        text-transform: uppercase;
                        user-select: none;
                        margin-bottom: 10px;
                        padding-top: 30px;
                    }
                    .size{
                        .price_title{
                            font-family: 'rubik_med';
                            color: #999999;
                            font-size: 12px;
                            // text-transform: uppercase;
                            user-select: none;
                            // padding-top: 2px;
                            margin-bottom: 2px;
                        }
                        .price_fields{
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            position: relative;
                            margin-bottom: 10px;
                            input{
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                margin-bottom: 0;
                            }
                            .devider{
                                margin: 0 15px 0 15px;
                                user-select: none;
                                color: #999999;
                            }
                        }
                    }



                    .group{
                        margin-top: 30px;
                        .group_title{
                            font-family: 'rubik_med';
                            text-transform: uppercase;
                            user-select: none;
                            margin-bottom: 10px;
                        }
                        .group_values{
                            overflow-y: auto;
                            height: auto;
                            max-height: 185px;
                            .group_values_item{

                            }
                            .item_list_input{
                                position: relative;
                                input{
                                    &+label{
                                        color: #aaaaaa;
                                    }
                                }
                                &.menugray{
                                    input{
                                        &+label{
                                            color: #2a2a2a;
                                        }
                                        &:not(:checked):not(:disabled)+label:hover{
                                            color: #f1002e;
                                            &::before{
                                                border: 1px solid #f28796;
                                            }
                                        }
                                        &:not(:disabled):active+label:hover{
                                            color: #f1002e;
                                            &::before{
                                                border: 1px solid #f28796;
                                                background-color: #f28796;
                                            }
                                        }
                                    }
                                    
                                    
                                }
                                // .show_btn{
                                //     width: 120px;
                                //     height: 5px;
                                //     background: brown;
                                //     position: absolute;
                                //     left: 100%;
                                //     top: 50%;
                                //     transform: translateY(-50%);
                                // }
                            }
                        }
                    }
                }
                .show_products_btn{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'rubik_med';
                    color: #ffffff;
                    background: #2a2a2a;
                    border-radius: 2px;
                    padding: 18px 20px;
                    text-transform: uppercase;
                    font-size: 12px;
                    margin: 30px 10px;
                    user-select: none;
                    text-decoration: none;
                    transition: all 0.2s;
                    &:hover{
                        background: #333333;
                    }
                }
            }
        }

        .top_controls{
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            justify-content: space-between;
            min-height: 60px;
            align-items: center;
            font-size: 13px;
            margin-bottom: 30px;
            .items_view_sort{
                display: flex;
                align-items: center;
                .subtext{
                    color: #999999;
                    user-select: none;
                    margin-right: 10px;
                }
                .item{
                    color: #666666;
                    margin-left: 20px;
                    padding: 10px;
                    transition: color 0.2s;
                    cursor: pointer;
                    user-select: none;
                    &:hover{
                        color: #f1002e;
                    }
                    &.selected{
                        color: #2a2a2a;
                        cursor: default;
                        &:hover{
                            color: #2a2a2a;
                        }
                    }
                }
            }
            .pricelist{
                background: #2a2a2a;
                color: #ffffff;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                padding: 10px 20px;
                cursor: pointer;
                transition: all 0.2s;
                border-radius: 3px;
                &:hover{
                    background: #333333;
                }
                img{
                    user-select: none;
                    margin-right: 20px;
                }
                .text{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    .title{
                        font-family: 'rubik_med';
                        font-size: 11px;
                        text-transform: uppercase;
                        user-select: none;
                    }
                    .subtext{
                        font-size: 11px;
                        user-select: none;
                    }
                }
            }
        }
        .items_view_item{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-bottom: 30px;
            max-width: 100%;
            &.closed{
                .info{
                    .info_top{
                        color: #aaaaaa;
                        .info_top_qty{
                            user-select: none;
                            opacity: 0;
                        }
                    }
                    .info_title {
                        color: #aaaaaa;
                    }
                    .info_parents{
                        .element{
                            .value{
                                color: #aaaaaa;
                            }
                        }
                    }
                    .info_price{
                        .price_item{
                            .card_item_price.actual{
                                font-size: 12px;
                                color: #f1002e;
                            }
                        }
                    }
                }
            }
            .info{
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                background: #f6f6f6;
                border-radius: 3px;
                margin-bottom: 5px;
                padding: 10px;
                .info_top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 11px;
                    & > div{
                        cursor: default;
                    }
                    .info_top_qty{
                        user-select: none;
                        &.yeap{
                            color: #0fb100;
                        }
                    }
                }
                .info_image{
                    background: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    height: 180px;
                    margin-top: 10px;
                    overflow: hidden;
                    .info_image_wrap{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            user-select: none;
                            // transform: scale(1);
                            // transition: all 0.2s;
                        }
                        
                    }
                    &:hover .info_image_wrap img{
                        // transform: scale(0.95);
                    }
                    
                }
                .info_title{
                    display: flex;
                    flex-grow: 1;
                    max-width: 100%;
                    overflow-x: hidden;
                    align-items: center;
                    justify-content: flex-start;
                    text-decoration: none;
                    padding: 10px 5px;
                    border-bottom: 1px solid #e5e5e5;
                    color: #2a2a2a;
                    font-size: 13px;
                    font-family: 'rubik_med';
                    transition: color 0.2s;
                    // text-align: center;
                    &:hover{
                        color: #f1002e;
                    }
                }
                .info_parents{
                    display: flex;
                    flex-direction: column;
                    padding: 10px 0;
                    .element{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 11px;
                        margin-bottom: 5px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .subtext{
                            color: #999999;
                            user-select: none;
                        }
                        .value{
                            text-decoration: none;
                            color: #2a2a2a;
                            font-family: 'rubik_med';
                            transition: color 0.2s;
                            &::first-letter{
                                text-transform: uppercase;
                            }
                            &:hover{
                                color: #f1002e;
                            }
                        }
                    }
                }
                .info_price{
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-evenly;
                    .price_item{
                        background: #ffffff;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-right: 5px;
                        padding: 10px 5px;
                        &:last-child{
                            margin-right: 0;
                        }
                        .subtext{
                            font-size: 9px;
                            display: flex;
                            color: #999;
                            user-select: none;
                            margin-bottom: 1px;
                        }
                        .card_item_price{
                            cursor: default;
                            font-size: 14px;
                            font-family: 'rubik_med';
                            &.base{
                                color: #999999;
                            }
                            &.actual{
                                color: #2a2a2a;
                            }
                        }
                    }
                }
            }
            .add_cart_btn{
                background: #2a2a2a;
                color: #ffffff;
                text-transform: uppercase;
                font-size: 12px;
                text-align: center;
                padding: 15px;
                user-select: none;
                cursor: pointer;
                transition: all 0.2s;
                border-radius: 3px;
                &:hover{
                    background: #333333;
                }
            }
        }
    }

    .categories_view{
        margin-top: 60px;
        display: none;
        &.active{
            display: block;
            animation: fadeup 0.4s;
        }
    }
}

.item_popup{
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    left: 0;
    top: 0;
    z-index: 2000;
    display: none;
    animation: fade 0.4s;
    &.show{
        display: block;
    }
    .popup_body{
        position: absolute;
        z-index: 20;
        background: #ffffff;
        padding: 20px;
    }
    .under_layer{
        position: fixed;
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        left: 0;
        top: 0;
        z-index: 10;
        background: rgba(0,0,0,0.7);
        cursor: pointer;
    }
}



@media (max-width: 991px) {
    .nodes{
        #find-count-items{
            display: none !important;
        }
        .nodes_page_title{
            h1{
                text-align: center;
            }
            .bread{
                flex-wrap: wrap;
                justify-content: center;
            }
        }
        .items_view{
            .filter_panel{
                &.show{
                    .items_view_filter{
                        transform: translateX(0%);
                        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
                    }
                    .filter_panel_mask{
                        display: block;
                    }
                }
                position: fixed;
                z-index: 3000;
                .items_view_filter{
                    position: fixed;
                    transform: translateX(-100%);
                    transition: all 0.3s;
                    width: 320px;
                    height: 100vh;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                    z-index: 3000;
                    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);                    
                    .close_icon{
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        cursor: pointer;
                        user-select: none;
                        z-index: 30;
                    }
                    .filter_wrap{
                        position: absolute;
                        width: 340px;
                        height: auto;
                        max-height: 100vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        padding: 50px 35px 70px 15px;
                        background: #ffffff;
                        z-index: 20;
                        top: 0;
                        left: 0;
                        .show_products_btn{
                            position: fixed;
                            bottom: 0;
                            margin: 0;
                            left: 0;
                            width: 320px;
                        }
                    }
                }
                .filter_panel_mask{
                    position: fixed;
                    width: 100vw;
                    display: none;
                    height: 100vh;
                    background: rgba(0,0,0,0.7);
                    cursor: pointer;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    animation: fade 0.3s;
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .nodes{
        .view_controls{
            .search_container .search{
                display: none !important;
            }
        }
        .items_view{
            margin-top: 20px;
            .filter_btn_mobile{
                margin-bottom: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background: #efefef;
                // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
                padding: 20px 30px 20px 10px;
                border-radius: 3px;
                img{
                    user-select: none;
                    display: flex;
                    margin-right: 8px;
                }
                span{
                    color: #2a2a2a;
                    user-select: none;
                    font-family: 'rubik_med';
                }
            }
        }
    }
}

@media(max-width: 767px){
    .nodes{
        .items_view{
            .top_controls{
                flex-direction: column;
                .items_view_sort{
                    width: 100%;
                    border-bottom: 1px solid #e5e5e5;
                    justify-content: center;
                }
                .pricelist{
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        .view_controls{
            padding-top: 10px;
            padding-bottom: 10px;
            .view_controls_btn{
                padding: 20px;
                &.active{
                    background: #fbfbfb;
                }
            }
            .search_container{
                display: none !important;
            }
        }
    }
}

@media (max-width: 539px){
    .nodes{
        .nodes_page_title{
            border-bottom: none;
            padding-bottom: 40px;
        }
        .view_controls{
            border-bottom: none;
            & > div{
                padding-right: 0;
                padding-left: 0;
            }
            .view_controls_btn{
                justify-content: center;
                background: #efefef;
                &.active {
                    color: #f1002e;
                    filter: grayscale(0%) !important;
                }
                img{
                    display: none;
                }
            }
        }
        .items_view{
            margin-top: 10px;
        }
    }
}
