.items_card{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 30px;
    .info{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        background: #f6f6f6;
        border-radius: 3px;
        margin-bottom: 5px;
        padding: 10px;
        .info_top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 11px;
            & > div{
                cursor: default;
            }
            .info_top_qty{
                user-select: none;
                &.yeap{
                    color: #0fb100;
                }
            }
        }
        .info_image{
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            height: 180px;
            margin-top: 10px;
            overflow: hidden;
            .info_image_wrap{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    user-select: none;
                    // transform: scale(1);
                    // transition: all 0.2s;
                }
                
            }
            &:hover .info_image_wrap img{
                // transform: scale(0.95);
            }
            
        }
        .info_title{
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            padding: 10px;
            border-bottom: 1px solid #e5e5e5;
            color: #2a2a2a;
            font-size: 13px;
            font-family: 'rubik_med';
            transition: color 0.2s;
            text-align: center;
            &:hover{
                color: #f1002e;
            }
        }
        .info_parents{
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            .element{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 11px;
                margin-bottom: 5px;
                &:last-child{
                    margin-bottom: 0;
                }
                .subtext{
                    color: #999999;
                    user-select: none;
                }
                .value{
                    text-decoration: none;
                    color: #2a2a2a;
                    font-family: 'rubik_med';
                    transition: color 0.2s;
                    &::first-letter{
                        text-transform: uppercase;
                    }
                    &:hover{
                        color: #f1002e;
                    }
                }
            }
        }
        .info_price{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-evenly;
            .price_item{
                background: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-right: 5px;
                padding: 10px 5px;
                &:last-child{
                    margin-right: 0;
                }
                .subtext{
                    font-size: 9px;
                    display: flex;
                    color: #999;
                    user-select: none;
                    margin-bottom: 1px;
                }
                .card_item_price{
                    cursor: default;
                    font-size: 14px;
                    font-family: 'rubik_med';
                    &.base{
                        color: #999999;
                    }
                    &.actual{
                        color: #2a2a2a;
                    }
                }
            }
        }
    }
    .add_cart_btn{
        background: #2a2a2a;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        padding: 15px;
        user-select: none;
        cursor: pointer;
        transition: all 0.2s;
        border-radius: 3px;
        &:hover{
            background: #333333;
        }
    }
}