.invoice_list{

    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }
    
    .search{
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: flex-end;
        margin-bottom: 30px;
        .search_field{
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &.name{
                flex-grow: 1;
            }
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
            input{
                height: 46px;
            }
            .search_btn{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    background: #333333;
                }
            }
        }
    }
    
    table{
        tbody{
            tr{
                td{
                    &.invoice{
                        .number{
                            color: #999999;
                            cursor: default;
                            margin-bottom: 3px;
                            a{
                                cursor: pointer;
                                color: #2a2a2a;
                                text-decoration: none;
                                transition: all 0.2s;
                                font-family: 'rubik_med';
                                &:hover{
                                    color: #f1002e;
                                }
                            }
                        }
                        .date{
                            font-size: 12px;
                            cursor: default;
                        }
                    }
                    &.order .text{
                        cursor: default;
                        color: #999999;
                        a{
                            cursor: pointer;
                            color: #2a2a2a;
                            text-decoration: none;
                            transition: all 0.2s;
                            font-family: 'rubik_med';
                            &:hover{
                                color: #f1002e;
                            }
                        }
                    }
                    &.summ{
                        text-align: right;
                        cursor: default;
                        font-family: 'rubik_med';
                    }
                }
            }
        }
    }
}

@media (max-width:1239px) {
    .invoice_list{
        .search{
            .search_field{
                &.name{
                    min-width: 420px;
                }
            }
        }
    }
}

@media (max-width:991px) {
    .invoice_list{
        .search{
            .search_field{
                &.name{
                    min-width: unset;
                    // width: 100%;
                }
                // &.date{
                //     flex-grow: 0.5;
                // }
            }
        }
    }
}

@media (max-width:767px) {
    .invoice_list{
        .search{
            .search_field{
                &.name{
                    width: 100%;
                }
                &.date{
                    flex-grow: 0.5;
                }
                &.btn {
                    flex-grow: 0.5;
                }
            }
        }
    }
}

@media (max-width:539px) {
    .invoice_list{
        .search{
            .search_field{
                &.name{
                    min-width: unset;
                }
                &.date, &.btn{
                    flex-grow: unset;
                    width: 100%;
                }
            }
        }
    }
}