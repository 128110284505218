.card_item{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    flex-grow: 1;
    .card_item_content{
        padding: 10px;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        
        .card_item_status_info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background: #ffffff;
            padding: 5px 10px;
    
            .card_item_status_info_code{
                display: flex;
                background: #ffffff;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0px;
                font-family: 'roboto_med';
                font-size: 11px;
                .subtext{
                    color: #999999;
                    user-select: none;
                    margin-right: 5px;
                }
                .value{
                    color: #2a2a2a;
                    cursor: default;
                }
            } 
    
            .qty_status{
                display: flex;
                align-items: center;
                color: #2a2a2a;
                padding: 5px 0px;
                font-size: 11px;
                font-family: 'roboto_med';
                cursor: default;
                img{
                    width: 12px;
                    margin-right: 5px;
                }
            }
            
        }
        .card_item_image{
            position: relative;
            flex-direction: column;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 10px 0;
            box-sizing: border-box;
            text-decoration: none;
            .pic{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 180px;
                padding: 10px 10px 10px 10px;
                background: #ffffff;
                position: relative;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    user-select: none;
                    object-fit: scale-down;
                    transform: scale(1);
                    transition: transform 0.2s;
                }
                &:hover img {
                    transform: scale(0.95);
                }
            }
            
            
            
        }
        .card_item_name{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            flex-grow: 1;
            padding: 10px 10px 10px 10px;
            text-transform: uppercase;
            font-family: 'roboto_med';
            flex-grow: 1;
            background: #ffffff;
            
            .name{
                display: flex;
                color: #2a2a2a;
                text-decoration: none;
                transition: color 0.2s;
                &:hover{
                    color: #f1002e;
                }
            }
            .size{
                display: flex;
                flex-wrap: nowrap;
                color: #999999;
                text-transform: none;
                cursor: default;
            }
        }
        .card_item_info{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-bottom: 10px;
    
            .card_item_info_el{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px;
                .subtext{
                    user-select: none;
                    color: #666666;
                    font-size: 12px;
                }
                .link{
                    color: #2a2a2a;
                    text-decoration: none;
                    transition: color 0.2s;
                    text-transform: capitalize;
                    font-family: 'roboto_med';
                    display: flex;
                    &:hover{
                        color: #f1002e;
                    }
                }
            }
    
    
    
    
    
            .card_item_info_parents{
                display: flex;
                justify-content: center;
                margin-top: 30px;
                .link{
                    color: #666666;
                    text-decoration: none;
                    transition: color 0.2s;
                    text-transform: capitalize;
                    font-family: 'roboto_med';
                    display: flex;
                    &:hover{
                        color: #f1002e;
                    }
                    &::after{
                        content: '//';
                        display: flex;
                        margin: 0 5px;
                        color: #999999;
                    }
                    &:last-child::after{
                        display: none;
                        content: '';
                    }
                }
            }
        }
        .card_item_price{
            display: flex;
            justify-content: space-evenly;
            .price_item{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #ffffff;
                margin-right: 10px;
                padding: 10px 5px;
                font-family: 'roboto_med';
                &:last-child{
                    margin-right: 0;
                }
                .subtext{
                    font-size: 9px;
                    display: flex;
                    color: #999999;
                    user-select: none;
                    margin-bottom: 5px;
                }
                .card_item_price{
                    cursor: default;
                    font-size: 16px;
                    &.base{
                        color: #999999;
                    }
                    &.actual{
                        color: #2a2a2a;
                    }
                }
            }
        }
    
    }

    


    .add_cart_btn{
        background: #393939;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
        margin-top: 10px;
        text-transform: uppercase;
        font-family: 'roboto_med';
        font-size: 11px;
        cursor: pointer;
        transition: all 0.2s;
        user-select: none;
        &:hover{
            background: #2a2a2a;
        }
    }
}

.card_item_popup{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3000;
    left: 0;
    top: 0;
    animation: fade 0.3s;
    &.show{
        display: block;
    }

    .card_item_popup_body{
        position: absolute;
        background: #ffffff;
        padding: 50px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
        max-width: 100vw;
        max-height: 100vh;
        overflow: hidden;
        width: max-content;
        .popup_close{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            cursor: pointer;
            opacity: 0.8;
            transition: opacity 0.2s;
            img{
                display: flex;
                user-select: none;
                width: 100%;
            }
            &:hover{
                opacity: 1;
            }
        }
        .popup_body_tabs{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            .tab_btn{
                color: #999999;
                text-transform: uppercase;
                cursor: pointer;
                transition: color 0.2s;
                padding: 10px 0;
                margin-right: 40px;
                font-family: 'roboto_med';
                user-select: none;
                display: flex;
                flex-wrap: nowrap;
                &:hover{
                    color: #2a2a2a;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    color: #f1002e;
                }
            }
        }
        .popup_body_tabcontent{
            .tabcontent_item{
                display: none;
                opacity: 0;
                visibility: hidden;
                animation: fade 0.3s;
                flex-direction: column;
                &.active{
                    display: flex;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .card_item_popup_mask{
        position: fixed;
        background: rgba(0,0,0,0.75);
        width: 100%;
        height: 100%;
        z-index: 50;
        left: 0;
        top: 0;
        cursor: pointer;
    }
}