.infopage{
    h2{
        font-size: 21px;
        font-weight: normal;
        margin-bottom: 30px;
        padding-top: 20px;
    }
    h4{
        font-size: 16px;
        margin-bottom: 20px;
        padding-top: 20px;
        font-weight: normal;
    }
    p{
        margin-bottom: 15px;
        line-height: 21px;
        color: #666666;
    }
    img{

    }
    ol{
        padding-left: 30px;
        li{
            margin-bottom: 15px;
            line-height: 21px;
            color: #666666;
        }
    }
}