.user_edit{
    .user_edit_form{
        .account{
            display: flex;
            align-items: center;
            margin-bottom: 60px;
            .account_info{
                display: flex;
                font-size: 21px;
                margin-right: 40px;
                .subtext{
                    color: #999999;
                    user-select: none;
                    margin-right: 10px;
                    font-family: 'rubik_light';
                }
                .value{
                    color: #2a2a2a;
                    font-family: 'rubik_med';
                }
            }
            .change_pwd{
                color: #2a2a2a;
                text-decoration: none;
                transition: all 0.2s;
                padding: 6px 10px;
                background-color: #f6f6f6; 
                &:hover{
                    color: #f1002e;
                }
            }
        }
        .field{
            margin-bottom: 20px;
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
        }
        .user_edit_form_btn{
            padding: 0 15px;
            .btn{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    background: #333333;
                }
            }
        }
    }
}

@media (max-width: 539px) {
    .user_edit{
        .user_edit_form{
            .account{
                flex-direction: column;
                align-items: flex-start;
                .account_info{
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
            .field{
                margin-bottom: 10px;
            }
        }
    }
}