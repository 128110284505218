.footer{
    margin-top: 120px;
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
    .logo{
        display: inline-flex;
        justify-self: flex-start;
        img{
            display: flex;
            user-select: none;
            &.text{
                margin-left: 6px;
            }
        }
    }
    .footer_mid{
        margin-top: 70px;
        margin-bottom: 70px;
        .footer_infotext{
            font-family: 'rubik_light';
            font-size: 32px;
            color: #2a2a2a;
            user-select: none;
            margin-bottom: 60px;
        }
        .footer_contacts{
            
            .footer_contacts_item{
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                .subtext{
                    color: #999999;
                    user-select: none;
                    font-size: 10px;
                    text-transform: uppercase;
                }
                .value{
                    text-decoration: none;
                    color: #2a2a2a;
                    font-size: 18px;
                    transition: color 0.2s;
                    &:hover{
                        color: #f1002e;
                    }
                }
            }
        }
        .footer_links_title{
            text-transform: uppercase;
            color: #2a2a2a;
            user-select: none;
            font-family: 'rubik_med';
            margin-bottom: 30px;
        }
        .footer_links_list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .link{
                color: #999999;
                text-decoration: none;
                padding: 10px 0;
                transition: color 0.2s;
                user-select: none;
                &:hover{
                    color: #f1002e;
                }
            }
        }
    }
    .copyright{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        margin-bottom: 60px;
        .copyright_text{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .copyright_text_item{
                display: flex;
                align-items: center;
                color: #999999;
                font-size: 12px;
                user-select: none;
                text-decoration: none;
                transition: color 0.2s;
                &::after{
                    content: '';
                    display: flex;
                    background: #999999;
                    width: 1px;
                    height: 10px;
                    margin: auto 15px;
                }
                &:last-child::after{
                    display: none;
                }
            }
            a.copyright_text_item:hover{
                color: #f1002e;
            }
        }
        .copyright_social{
            display: flex;
            align-items: center;
            .copyright_social_item{
                margin-left: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    display: flex;
                    user-select: none;
                    filter: grayscale(100%);
                    transition: all 0.2s;
                }
                &:hover img{
                    filter: grayscale(0%);
                }
            }
        }
    }
}

@media (max-width: 767px){
    .footer{
        padding-top: 60px;
        .footer_mid{
            margin-top: 50px;
            .footer_contacts{
                margin-bottom: 50px;
            }
            // .footer_links_title{
            //     margin-top: 40px;
            // }
        }
        .copyright{
            .copyright_text{
                flex-direction: column;
                align-items: flex-start;
                .copyright_text_item{
                    padding: 3px 0;
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 539px){
    .footer{
        padding-top: 60px;
        .footer_mid{
            margin-top: 50px;
            margin-bottom: 50px;
            .footer_contacts{
                margin-bottom: 10px;
            }
            .footer_links_title{
                margin-top: 40px;
            }
        }
        .copyright{
            flex-direction: column-reverse;
            align-items: flex-start;
            .copyright_social{
                margin-bottom: 40px;
                .copyright_social_item{
                    margin-left: 0;
                    margin-right: 30px;
                }
            }
        }
    }
}