.item_details{
    margin-top: 60px;
    margin-bottom: 80px;
    .custom{
        flex-direction: column;
        align-items: flex-start;
    }

    .bread{
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        flex-wrap: wrap;
        .bread_item{
            text-decoration: none;
            min-width: 20px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999999;
            transition: color 0.2s;
            font-size: 12px;
            line-height: 19px;
            &:hover{
                color: #f1002e;
                img{
                    filter: grayscale(0%);
                    opacity: 1;
                }
            }
            img{
                display: flex;
                user-select: none;
                transition: all 0.2s;
                filter: grayscale(100%);
                opacity: 0.5;
            }
            &.last,
            &.last:hover{
                cursor: default;
                color: #2a2a2a;
                
            }
        }
        .devider{
            margin: auto 12px;
            user-select: none;
            &:last-child{
                display: none;
            }
        }
    }

    .item_details_image{
        padding: 50px;
        border: 1px solid #eeeeee;
        margin-right: 50px;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        max-height: 520px;
        img{
            display: flex;
            object-fit: scale-down;
            user-select: none;
            transform: scale(1);
            transition: transform 0.2s;
            height: 100%;
            width: 100%;
        }
        &:hover{
            border: 1px solid #cccccc;
            img{
                transform: scale(0.95);
            }
        }
    }


    .item_details_article{
        display: flex;
        margin-top: 15px;
        .subtext{
            color: #999999;
            user-select: none;
            margin-right: 5px;
        }
        .value{
            color: #2a2a2a;
            font-family: 'rubik_med';
            cursor: default;
        }
    }

    h1{
        margin: 5px 0 30px 0;
        // font-family: 'roboto_reg';
        font-size: 42px;
    }

    .closed_product{
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        color: #f1002e;
        font-size: 16px;
        font-family: 'rubik_med';
        img{
            user-select: none;
            margin-right: 10px;
        }
        .text{
            user-select: none;
        }
    }

    .item_details_stock{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .item_details_stock_val{
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #2a2a2a;
            font-family: 'rubik_med';
            transition: color 0.2s;
            img{
                user-select: none;
                width: 16px;
                margin-right: 5px;
            }
            .text{
                margin-right: 5px;
            }
            &.instock{
                color: #00bd00;
                &:hover{
                    color: #019201;
                }
            }
            &.outstock{
                color: #999999;
                cursor: default;
            }
        }
    }

    .item_details_price{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        flex-grow: 1;
        .item_details_price_val{
            font-size: 42px;
            font-family: 'rubik_med';
            color: #2a2a2a;
            cursor: default;
            span{
                font-size: 16px;
            }
        }
        .item_details_price_val_base{
            margin-left: 40px;
            color: #999999;
            font-size: 21px;
            .subtext{
                font-size: 11px;
                font-family: 'rubik_med';
                user-select: none;
            }
            .value{
                font-family: 'rubik_med';
                cursor: default;
                color: #666666;
                span{
                    font-size: 16px;
                }
            }
        }
    }

    .item_details_info{
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 1px solid #eeeeee;
        margin-bottom: 30px;
        .item{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
            background: #ffffff;
            &:nth-child(2n){
                background: #f9f9f9;
            }
            &:last-child{
                border-bottom: none;
            }
            .subtext{
                width: 180px;
                overflow: hidden;
                user-select: none;
                padding: 12px 20px;
                border-right: 1px solid #eeeeee;

            }
            .value{
                color: #2a2a2a;
                text-transform: capitalize;
                font-family: 'rubik_med';
                padding: 12px 20px;
                cursor: default;
            }
            a.value{
                text-decoration: none;
                transition: color 0.2s;
                color: #2a2a2a;
                cursor: pointer;
                &:hover{
                    color: #f1002e;
                }
            }
        }
    }



    .qty{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        .counter{
            border: 1px solid #eee;
            padding: 0;
            display: flex;
            height: max-content;
            align-items: stretch;
            background: #ffffff;
            input{
                padding: 10px;
                background: transparent;
                width: 80px;
                font-size: 12px;
                font-family: 'rubik_med';
                text-align: center;
                &:focus::placeholder{
                    opacity: 0;
                }
            }
            .control_btns{
                display: flex;
                flex-direction: column;
                border-left: 1px solid #eeeeee;
                flex-grow: 1;
                & > div{
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                    cursor: pointer;
                    user-select: none;
                    &:first-child{
                        border-bottom: 1px solid #eeeeee;
                    }
                }
            }
        }
        .units{
            color: #999999;
            font-size: 12px;
            user-select: none;
            margin-left: 10px;
            min-width: 50px;
        }

        .unitconvert{
            display: flex;
            align-items: center;
            margin-left: 50px;
            .unitconvert_item{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                font-size: 12px;
                padding: 5px 10px;
                height: 36px;
                width: 100px;
                margin: 0 5px;
                border: 1px solid #eeeeee;
                color: #2a2a2a;
                transition: all 0.2s;
                &:hover{
                    border: 1px solid #cccccc;
                    background: #f9f9f9;
                }
                .value{
                    font-family: 'rubik_med';
                }
                .subtext{
                    margin-left: 5px;
                }
            }
        }
        .summ{
            margin-left: 40px;
            display: flex;
            align-items: baseline;
            .value{
                cursor: default;
                font-size: 36px;
                font-family: 'rubik_med';
                color: #2a2a2a;
                margin-right: 8px;
                line-height: 36px;
            }
            .curr{
                cursor: default;
                font-size: 21px;
                font-family: 'rubik_med';
                color: #2a2a2a;
            }
        }
    }





    .add_cart_btn{
        background: #393939;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 20px 50px;
        margin-top: 10px;
        text-transform: uppercase;
        font-family: 'rubik_med';
        font-size: 12px;
        cursor: pointer;
        transition: all 0.2s;
        user-select: none;
        // min-width: 260px;
        &:hover{
            background: #2a2a2a;
        }
        img{
            margin-right: 10px;
            user-select: none;
            margin-left: -10px;
        }
        .curr{
            margin-left: 5px;
        }
    }
    .summ{
        margin-bottom: 20px;
        margin-top: 10px;
        display: flex;
        align-items: baseline;
        .value{
            cursor: default;
            font-size: 36px;
            font-family: 'rubik_med';
            color: #2a2a2a;
            margin-right: 8px;
            line-height: 36px;
        }
        .curr{
            cursor: default;
            font-size: 21px;
            font-family: 'rubik_med';
            color: #2a2a2a;
        }
    }

    .item_details_props, 
    .item_details_qty{
        margin-top: 80px;
        h2{
            margin-bottom: 30px;
            font-family: 'rubik_reg';
            font-weight: unset;
            font-size: 24px;
            user-select: none;
        }

        .table_container{
            max-width: 100%;
            overflow-x: auto;
            table{
                tbody{
                    .td_info{
                        cursor: default;
                        width: 100%;
                        .stock{
                            color: #2a2a2a;
                            font-family: 'rubik_med';
                        }
                        .props{
                            display: flex;
                            margin-top: 5px;
                            & > div{
                                margin-right: 15px;
                                span{
                                    margin-left: 0px;
                                }
                            }
                        }
                    }
                    .td_cost{
                        cursor: default;
                        .cost{
                            color: #999999;
                            span{
                                white-space: nowrap;
                                color: #2a2a2a;
                                font-family: 'rubik_med';
                            }
                        }
                    }
                    .td_counter{
                        // display: flex;
                        // flex-direction: row;
                        // align-items: center;
                        .counter_wrap{
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            .counter{
                                border: 1px solid #eee;
                                padding: 0;
                                display: flex;
                                background: #ffffff;
                                input{
                                    padding: 10px;
                                    background: transparent;
                                    width: 80px;
                                    text-align: center;
                                    &:focus::placeholder{
                                        opacity: 0;
                                    }
                                }
                                .control_btns{
                                    display: flex;
                                    flex-direction: column;
                                    border-left: 1px solid #eeeeee;
                                    flex-grow: 1;
                                    & > div{
                                        width: 20px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-grow: 1;
                                        cursor: pointer;
                                        user-select: none;
                                        &:first-child{
                                            border-bottom: 1px solid #eeeeee;
                                        }
                                    }
                                }
                            }
                            .units{
                                margin-left: 8px;
                            }
                        }

                        .unitconvert{
                            display: flex;
                            margin-top: 10px;
                            align-items: center;
                            .unitconvert_item{
                                display: flex;
                                justify-content: center;
                                padding: 5px;
                                flex-wrap: nowrap;
                                align-items: center;
                                cursor: pointer;
                                margin-right: 5px;
                                font-size: 12px;
                                color: #2a2a2a;
                                background: #eeeeee;
                                width: 80px;
                                transition: all 0.2s;
                                &:last-child{
                                    margin-right: 0px;
                                }
                                &:hover{
                                    color: #f1002e;
                                }
                                .value{
                                    font-family: 'rubik_med';
                                    margin-right: 5px;
                                }
                            }
                        }
                        
                        .summ{
                            margin-left: 20px;
                            display: flex;
                            border-right: none;
                            .value{
                                cursor: default;
                                // font-size: 42px;
                                padding: 0;
                                font-family: 'rubik_med';
                                color: #2a2a2a;
                                margin-right: 3px;
                            }
                            .curr{
                                cursor: default;
                                // font-size: 21px;
                                font-family: 'rubik_med';
                                color: #2a2a2a;
                            }
                        }
                    }
                    .td_btn{
                        .add_btn.summ{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 12px 20px;
                            cursor: pointer;
                            background: #393939;
                            transition: all 0.2s;
                            color: #ffffff;
                            font-size: 11px;
                            text-transform: uppercase;
                            font-family: 'rubik_med';
                            width: 160px;
                            margin: 0;
                            &:hover{
                                background: #2a2a2a;
                            }
                            img{
                                width: 16px;
                                display: flex;
                                user-select: none;
                                margin-right: 8px;
                            }
                            span{
                                color: #ffffff;
                                font-size: 11px;
                                cursor: pointer;
                                &.value{
                                    margin-right: 3px;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }

        .table{
            display: flex;
            width: 100%;
            flex-direction: column;
            border: 1px solid #eeeeee;
            .item{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: stretch;
                border-bottom: 1px solid #eeeeee;
                background: #ffffff;
                &:nth-child(2n){
                    background: #f9f9f9;
                }
                &:last-child{
                    border-bottom: none;
                }
                .subtext{
                    width: 260px;
                    overflow: hidden;
                    user-select: none;
                    padding: 12px 30px;
                    border-right: 1px solid #eeeeee;

                }
                .value{
                    color: #2a2a2a;
                    text-transform: capitalize;
                    font-family: 'rubik_med';
                    padding: 12px 40px;
                    cursor: default;
                    width: 100%;
                }
            }
        }
    }
    .item_details_qty{
        // margin-bottom: 80px;
        .table{
            .item{
                justify-content: space-between;
                margin: 0;
                align-items: stretch;
                & > div{
                    padding: 20px 20px;
                    border-right: 1px solid #eeeeee;
                    display: flex;
                    color: #999999;
                    align-items: center;
                    margin-top: 0;
                    margin-bottom: 0;
                    cursor: default;
                    span{
                        font-family: 'rubik_med';
                        color: #2a2a2a;
                        margin-left: 5px;
                        white-space: nowrap;
                    }
                    &.info{
                        width: 100%;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 20px 20px 20px 30px;
                        .stock{
                            color: #2a2a2a;
                            font-family: 'rubik_med';
                        }
                        .props{
                            display: flex;
                            margin-top: 5px;
                            & > div{
                                margin-right: 15px;
                                span{
                                    margin-left: 0px;
                                }
                            }
                        }
                    }
                    &.cost{
                        min-width: 200px;
                    }
                    &.counter{
                        border: 1px solid #eee;
                        padding: 0;
                        display: flex;
                        height: max-content;
                        margin: auto 10px auto 30px;
                        align-items: stretch;
                        background: #ffffff;
                        input{
                            padding: 10px;
                            background: transparent;
                            width: 80px;
                            text-align: center;
                            &:focus::placeholder{
                                opacity: 0;
                            }
                        }
                        .control_btns{
                            display: flex;
                            flex-direction: column;
                            border-left: 1px solid #eeeeee;
                            flex-grow: 1;
                            & > div{
                                width: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-grow: 1;
                                cursor: pointer;
                                user-select: none;
                                &:first-child{
                                    border-bottom: 1px solid #eeeeee;
                                }
                            }
                        }
                    }
                    &.summ{
                        margin-left: 10px;
                        display: flex;
                        padding: 20px 20px 20px 0;
                        border-right: none;
                        .value{
                            cursor: default;
                            // font-size: 42px;
                            padding: 0;
                            font-family: 'rubik_med';
                            color: #2a2a2a;
                            margin-right: 3px;
                        }
                        .curr{
                            cursor: default;
                            // font-size: 21px;
                            font-family: 'rubik_med';
                            color: #2a2a2a;
                        }
                    }
                }
                .add_btn{
                    display: flex;
                    margin: auto 30px auto 0;
                    // width: 34px;
                    // height: 34px;
                    align-items: center;
                    justify-content: center;
                    padding: 12px 20px;
                    cursor: pointer;
                    background: #393939;
                    transition: all 0.2s;
                    color: #ffffff;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-family: 'rubik_med';
                    &:hover{
                        background: #2a2a2a;
                    }
                    img{
                        width: 20px;
                        display: flex;
                        user-select: none;
                    }
                }
            }
        }
    }
    
}


@media (max-width:991px){
    .item_details{
        h1{
            font-size: 34px;
        }
        .item_details_qty{
            .table{

            }
        }
    }
}