.shipment_list{
    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }
    .search{
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: flex-end;
        margin-bottom: 30px;
        .search_field{
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &.name{
                flex-grow: 1;
            }
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
            input{
                height: 46px;
            }
            .search_btn{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    background: #333333;
                }
            }
        }
    }

    table{
        tbody{
            td{
                &.shipment_links{
                    display: flex;
                    flex-direction: column;
                    .numb{
                        color: #666666;
                        font-family: 'rubik_reg';
                        cursor: default;
                        font-size: 14px;
                        margin-bottom: 3px;
                        a{
                            color: #2a2a2a;
                            transition: all 0.2s;
                            font-family: 'rubik_med';
                            text-decoration: none;
                            &:hover{
                                color: #f1002e;
                            }
                        }
                    }
                    .doc{
                        color: #666666;
                        font-family: 'rubik_reg';
                        cursor: default;
                        font-size: 12px;
                        a{
                            color: #2a2a2a;
                            transition: all 0.2s;
                            font-family: 'rubik_med';
                            text-decoration: none;
                            &:hover{
                                color: #f1002e;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1599px) {
    .shipment_list{
        .search{
            .search_field{
                &.name{
                    min-width: 360px;
                }
            }
        }
    }
}

@media (max-width:991px) {
    .shipment_list{
        .search{
            .search_field{
                &.name{
                    width: 100%;
                }
                &.status{
                    flex-grow: 1;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .shipment_list{
        .search{
            .search_field{
                &.date{
                    flex-grow: 0.5;
                }
                &.status{
                    flex-grow: unset;
                    width: 100%;
                }
                &.btn {
                    flex-grow: 0.5;
                }
            }
        }
    }
}

@media (max-width:539px) {
    .shipment_list{
        .search{
            .search_field{
                &.name{
                    min-width: unset;
                }
                &.date, &.btn{
                    flex-grow: unset;
                    width: 100%;
                }
            }
        }
    }
}