.invoice_lines{

    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }
    
    .search{
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: flex-end;
        margin-bottom: 30px;
        .search_field{
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &.name, &.numb, &.code{
                width: 33.33%;
            }
            &.article{
                flex-grow: 1;
            }
            label{
                color: #2a2a2a;
                font-family: 'rubik_med';
                text-transform: uppercase;
                margin-bottom: 5px;
                user-select: none;
                font-size: 11px;
            }
            input{
                height: 46px;
            }
            .search_btn{
                margin-bottom: 10px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 40px;
                background: #2a2a2a;
                color: #ffffff;
                font-size: 12px;
                text-transform: uppercase;
                font-family: 'rubik_med';
                cursor: pointer;
                transition: all 0.2s;
                text-decoration: none;
                &:hover{
                    background: #333333;
                }
            }
        }
    }

    table{
        tbody{
            tr{
                td{
                    &.invoice{
                        .number{
                            color: #999999;
                            cursor: default;
                            margin-bottom: 3px;
                            a{
                                cursor: pointer;
                                color: #2a2a2a;
                                text-decoration: none;
                                transition: all 0.2s;
                                font-family: 'rubik_med';
                                white-space: nowrap;
                                &:hover{
                                    color: #f1002e;
                                }
                            }
                        }
                        .date{
                            font-size: 12px;
                            cursor: default;
                            white-space: nowrap;
                        }
                    }
                    &.order .text{
                        cursor: default;
                        color: #999999;
                        a{
                            white-space: nowrap;
                            cursor: pointer;
                            color: #2a2a2a;
                            text-decoration: none;
                            transition: all 0.2s;
                            font-family: 'rubik_med';
                            &:hover{
                                color: #f1002e;
                            }
                        }
                    }
                    &.product{
                        display: flex;
                        .item_image{
                            display: flex;
                            width: 100px;
                            min-width: 100px;
                            height: 100px;
                            align-items: center;
                            justify-content: center;
                            margin-right: 20px;
                            background: #ffffff;
                            padding: 5px;
                            border-radius: 3px;
                            .link{
                                width: 100%;
                                height: 100%;
                                img{
                                    user-select: none;
                                    object-fit: scale-down;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                }
                            }
                        }
                        .item_info{
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            // width: 100%;
                            align-items: flex-start;
                            .item_info_name{
                                display: flex;
                                font-size: 18px;
                                color: #2a2a2a;
                                text-decoration: none;
                                transition: color 0.2s;
                                font-family: 'rubik_light';
                                margin-top: 20px;
                                &:hover{
                                    color: #f1002e;
                                }
                            }
                            .item_info_props{
                                display: flex;
                                flex-direction: column;
                                .item_info_props_el{
                                    display: flex;
                                    flex-direction: row;
                                    margin-top: 5px;
                                    &:first-child{
                                        margin-top: 15px;
                                    }
                                    &:last-child{
                                        margin-bottom: 20px;
                                    }
                                    .subtext{
                                        color: #999999;
                                        user-select: none;
                                        font-size: 12px;
                                        margin-right: 6px;
                                    }
                                    .value{
                                        font-family: 'rubik_med';
                                        font-size: 12px;
                                        margin-right: 30px;
                                        cursor: default;
                                    }
                                }
                            }
                        }
                    }
                    &.price{
                        text-align: right;
                        white-space: nowrap;
                    }
                    &.amount{
                        text-align: right;
                        white-space: nowrap;
                        font-family: 'rubik_med';
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .invoice_lines{
        .search{
            .search_field{
                &.name, &.numb{
                    width: 50%;
                }
                &.code, &.article{
                    width: 50%;
                }
                &.date{
                    width: 50%;
                }
                &.btn{
                    width: 50%;
                }
            }
        }
    }
}

@media (max-width:539px) {
    .invoice_lines{
        .search{
            .search_field{
                &.name, &.numb{
                    width: 100%;
                }
                &.code, &.article{
                    width: 100%;
                }
                &.date{
                    width: 100%;
                }
                &.btn{
                    width: 100%;
                }
            }
        }
    }
}