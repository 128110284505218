.sales_item{
    .sales_item_info{
        margin-bottom: 50px;
        .info_item{
            background: #f6f6f6;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            padding: 30px 50px;
            margin-bottom: 20px;
            .subtext{
                color: #999999;
                user-select: none;
                font-size: 10px;
                font-family: 'rubik_med';
                text-transform: uppercase;
            }
            .value{
                font-size: 21px;
                color: #2a2a2a;
                cursor: default;
                font-family: 'rubik_light';
            }
        }
    }
    h2{
        font-weight: normal;
        font-size: 18px;
        user-select: none;
        margin-bottom: 20px;
    }

    .basket_list{
        border: 1px solid #e5e5e5;
        .basket_list_item{
            display: flex;
            border-bottom: 1px solid #e5e5e5;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            &:last-child{
                border-bottom: none;
            }
            .info_wrap{
                display: flex;
                flex-direction: row;
                padding: 0 15px 0 0;
                .item_image{
                    display: flex;
                    width: 140px;
                    min-width: 140px;
                    height: 120px;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 20px;
                    .link{
                        width: 100%;
                        height: 100%;
                        img{
                            user-select: none;
                            object-fit: scale-down;
                            width: 100%;
                            height: 100%;
                            display: flex;
                        }
                    }
                }
                .item_info{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    // width: 100%;
                    align-items: flex-start;
                    .item_info_name{
                        display: flex;
                        font-size: 18px;
                        color: #2a2a2a;
                        text-decoration: none;
                        transition: color 0.2s;
                        font-family: 'rubik_light';
                        margin-top: 20px;
                        &:hover{
                            color: #f1002e;
                        }
                    }
                    .item_info_props{
                        display: flex;
                        flex-direction: column;
                        .item_info_props_el{
                            display: flex;
                            flex-direction: row;
                            margin-top: 5px;
                            &:first-child{
                                margin-top: 15px;
                            }
                            &:last-child{
                                margin-bottom: 20px;
                            }
                            .subtext{
                                color: #999999;
                                user-select: none;
                                font-size: 12px;
                                margin-right: 6px;
                            }
                            .value{
                                font-family: 'rubik_med';
                                font-size: 12px;
                                margin-right: 30px;
                                cursor: default;
                            }
                        }
                    }
                }
            }
            .qtyprice_wrap{
                display: flex;
                flex-direction: row;
                padding: 0 15px;
                .item_price{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 140px;
                    min-width: 140px;
                    margin-right: 20px;
                    .subtext{
                        color: #999999;
                        font-size: 12px;
                        user-select: none;
                    }
                    .value{
                        font-family: 'rubik_med';
                        cursor: default;
                        color: #2a2a2a;
                    }
                }
            }
            
            .item_summ{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 160px;
                min-width: 160px;
                margin-left: 10px;
                margin-right: 50px;
                .subtext{
                    color: #999999;
                    font-size: 12px;
                    user-select: none;
                }
                .value{
                    font-size: 18px;
                    font-family: 'rubik_med';
                    cursor: default;
                }
            }
        }
    }

}

@media (max-width: 1239px) {
    .sales_item{
        .sales_item_info{
            margin-bottom: 30px;
            .info_item{
                padding: 20px 40px;
            }
        }
        .basket_list{
            .basket_list_item{
                .info_wrap{
    
                }
                .qtyprice_wrap{
                    flex-direction: column;
                    .item_price{
                        padding: 3px 0;
                        width: auto;
                        min-width: unset;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width:991px){
    .sales_item{
        .basket_list{
            .basket_list_item{
                .item_summ{
                    width: auto;
                    min-width: unset;
                    .value{
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .sales_item{
        h1{
            font-size: 26px;
        }
        .basket_list{
            .basket_list_item{
                display: block;
                margin-bottom: 20px;
                .info_wrap{
                    border-bottom: 1px solid #e5e5e5;
                    margin-bottom: 10px;
                }
                .qtyprice_wrap{
                    display: inline-flex;
                    flex-direction: row;
                    margin-left: 15px;
                    .item_price{
                        display: block;
                        margin-right: 20px;
                    }
                }
                .item_summ{
                    // display: inline-block;
                    float: right;
                    margin-right: 30px;
                    .value{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@media (max-width:539px) {
    .sales_item{
        .basket_list{
            .basket_list_item{
                .qtyprice_wrap{
                    display: flex;
                    flex-direction: column;
                    .item_price{
                        display: flex;
                        flex-direction: row;
                    }
                }
                .item_summ{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    float: none;
                    margin-left: 30px;
                    padding: 3px 0;
                }
            }
        }
    }
}