.shops{
    margin-bottom: 80px;
    .shops_region_list{
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        flex-wrap: wrap;
        .shops_region_btn{
            padding: 15px 30px;
            margin-right: 10px;
            font-size: 13px;
            margin-bottom: 10px;
            color: #a5a5a5;
            background: #f4f4f4;
            text-transform: uppercase;
            transition: all 0.2s;
            cursor: pointer;
            user-select: none;
            &:hover{
                color: #2a2a2a;
            }
            &.active{
                background: #2a2a2a;
                color: #ffffff;
                cursor: default;
            }
        }
    }
    .shops_region_tab{
        animation: fade 0.3s;
        &.hide{
            display: none;
        }
        .shops_town{
            font-size: 16px;
            text-transform: uppercase;
            font-family: 'rubik_reg';
            margin-bottom: 30px;
            user-select: none;
        }
        .shops_shop_wrap{
            display: flex;
            flex-grow: 1;
            margin-bottom: 30px;
            .shops_shop{
                background: #f4f4f4;
                padding: 30px 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                .icon{
                    width: 40px;
                    height: 40px;
                    background: #ffffff;
                    margin-bottom: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 100%;
                        display: flex;
                        user-select: none;
                        object-fit: scale-down;
                    }
                }
                .shop_name{
                    color: #2a2a2a;
                    cursor: default;
                    font-size: 15px;
                    // font-family: 'rubik_med';
                    margin-bottom: 10px;
                }
                .shop_adress{
                    color: #a5a5a5;
                    font-size: 12px;
                    // font-family: 'rubik_med';
                    margin-bottom: 10px;
                    cursor: default;
                }
                .shop_phone{
                    color: #a5a5a5;
                    font-size: 12px;
                    // font-family: 'rubik_med';
                    cursor: default;
                }
            }
        }
    }
}